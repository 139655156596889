import { defineStore } from "pinia";

import Profile from "../models/Profile";
import BaseStore from "@/core/classes/base/BaseStore";
import ProfileService from "../services/ProfileService";

export const useProfileStore = defineStore("profileStore", {
  state: () => ({
    profile: null,
  }),
  getters: {},
  actions: {
    getProfile() {
      return new Promise((resolve, reject) => {
        ProfileService.getProfile()
          .then((res) => {
            let { data, status } = res;
            return ProfileService.resolveStatusResponse(status, res).then(
              () => {
                this.profile = new Profile(data.data);
                return resolve(res);
              }
            );
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changePassword(data) {
      return BaseStore.promiseHandler(() =>
        ProfileService.changePassword(data)
      );
    },
  },
});
