<template>
  <BaseDecoratorWrapper :rightDecorationOnly="true">
    <!-- TODO: find another better way than hardcoded the height -->
    <v-container fill-height  style="height: 70vh;"> 
      <div v-if="!isWebView">
        <div class="circle circle-1 d-none d-md-flex"></div>
        <div class="circle circle-2"></div>
        <div class="circle circle-3"></div>
      </div>

      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" lg="6" align="center" justify="center">
          <h2 class="primary--text">
            تقدم منصة جمعية البركة خدمة إنشاء سيرة ذاتية وتصديرها بصيغة PDF<br />
            قم باختيار اللغة الهدف واتبع خطوات الإنشاء
          </h2>

          <v-radio-group class="my-10" row v-model="selectedLanguage">
            <v-radio color="accent" value="ar">
              <h3
                slot="label"
                :class="{ 'accent--text': selectedLanguage == 'ar' }"
              >
                اللغة العربية
              </h3>
            </v-radio>
            <v-spacer></v-spacer>
            <v-radio color="accent" value="en">
              <h3
                slot="label"
                :class="{ 'accent--text': selectedLanguage == 'en' }"
              >
                اللغة الانكليزية
              </h3>
            </v-radio>
          </v-radio-group>

          <v-btn
            large
            class="light rounded-xl pa-5"
            style="opacity: 0.8"
            :to="{ path: '/create-resume/steps', query: $route.query }"
          >
            <span class="primary--text ml-3">ابدأ بإنشاء السيرة الذاتية</span>
            <v-icon color="accent">mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </BaseDecoratorWrapper>
</template>

<script>
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../store/CreateResumeStore";

export default {
  props: {
    isWebView: String,
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["selectedLanguage"]),
  },
};
</script>

<style scoped>
.circle {
  position: fixed;
  left: -200px;
  top: -200px;
  border-radius: 50%;
  background-color: #f7941d;
  z-index: 0;
  opacity: 0.2;
}

.circle-1 {
  width: 1000px;
  height: 1000px;
  animation: circle-animation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.circle-2 {
  width: 750px;
  height: 750px;
  animation: circle-animation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}

.circle-3 {
  width: 500px;
  height: 500px;
  animation: circle-animation;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes circle-animation {
  0% {
    top: -1346px;
    left: -1075px;
  }

  100% {
    left: -200px;
    top: -200px;
  }
}
</style>
