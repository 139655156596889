<template>
  <BaseTextField
    :append-icon="showPassword ?'mdi-eye-off': 'mdi-eye' "
    v-on="$listeners"
    v-bind="$attrs"
    @click:append="showPassword = !showPassword"
    :type="showPassword ? 'text' :'password'"
    persistent-placeholder
  >
  </BaseTextField>
</template>
<script>
export default {
  name: "BasePasswordField",
  data() {
    return {
      showPassword: false,
    };
  },
};
</script>
