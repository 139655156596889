<template>
  <v-container class="pa-0 ma-0">
    <h2 class="pa-0 ma-0">{{ title }}</h2>
    <v-divider color="white" class="pa-0 ma-0 mb-2"></v-divider>
  </v-container>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style></style>
