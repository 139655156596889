<template>
  <v-container v-if="resume.projects.length > 0" class="py-2">
    <BaseDivider :title="this.$t('projects')" />
    <div class="mb-1" v-for="(project, i) in resume.projects" :key="i">
      <template>
        <h4>
          {{ project.name }} | {{ $t("from") }} {{ project.startDate }}
          {{ $t("to") }}
          {{ project.present ? $t("present") : project.endDate }}
          <v-btn
            text
            v-if="project.link"
            :href="project.link"
            target="_blank"
            class="mx-2"
          >
            <v-icon color="gray">mdi-link-variant</v-icon>
          </v-btn>
        </h4>
        <p v-if="project.description" class="description">
          {{ project.description }}
        </p>
      </template>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import BaseDivider from "./BaseDivider.vue";
export default {
  computed: {
    ...mapState(useCreateResumeStore, ["resume", "selectedColor"]),
    linkStyle() {
      return "color: " + this.selectedColor;
    },
    projectLink() {
      return this.$t("ph_project_link");
    },
  },
  components: { BaseDivider },
};
</script>

<style scoped>
.description {
  opacity: 0.7;
  display: inline;
}
</style>
