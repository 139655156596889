import { defineStore } from "pinia";
import HomeService from "../services/HomeService";
import EthicalAdvice from "../models/EthicalAdvice";

export const useHomeStore = defineStore("homeStore", {
  state: () => ({
    ethicalAdvices: [],
  }),
  getters: {},
  actions: {
    getEthicalAdvices() {
      return new Promise((resolve, reject) => {
        HomeService.getEthicalAdvices()
          .then((res) => {
            let { data, status } = res;
            data;
            return HomeService.resolveStatusResponse(status, res).then(() => {
              this.ethicalAdvices=[];
              data?.data?.map((advice) => {
                this.ethicalAdvices.push(new EthicalAdvice(advice));
              });
              return resolve(res);
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    sendRating(data) {
      return new Promise((resolve, reject) => {
        HomeService.sendRating(data)
          .then((res) => {
            let { data, status } = res;
            data;
            return HomeService.resolveStatusResponse(status, res).then(() => {
              return resolve(res);
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
