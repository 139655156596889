export default class TrainingProgramQuestion {
  id;
  label;
  placeholder;
  note;
  stepNumber;
  isRequired;
  answers=[];
  type;
  constructor(data){
    this.id=data?.id;
    this.label=data?.label;
    this.placeholder=data?.placeholder;
    this.note=data?.note;
    this.stepNumber=data?.step_number;
    this.isRequired=data?.is_required;
    this.answers=data?.answers;
    this.type=data?.type;
  }
}
