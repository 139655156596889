<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    :persistent="persistent"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card elevation="2">
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12" sm="6">
            <v-row justify="center" class="my-5">
              <h3 class="secondary--text">
                <slot name="title"></slot>
              </h3>
              <v-btn
                v-if="!persistent"
                text
                dense
                outlined
                rounded
                class="close-btn"
                @click="close()"
                ><v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
            <v-col>
              <slot name="fullBody"></slot>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- <v-card :class="{ ltr: ltr }" elevation="2" class="pa-5">
      <v-btn
        v-if="!persistent"
        text
        dense
        outlined
        rounded
        class="close-btn"
        @click="close()"
        ><v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title class="mt-3">
        <v-spacer></v-spacer>
        <slot name="title"></slot>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions"></slot>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <slot name="fullBody"></slot> -->
  </v-dialog>
</template>

<script>
export default {
  name: "BaseFullScreenDialog",
  props: {
    showCondition: Boolean,
    persistentCondition: {
      type: Boolean,
      default: false,
    },
    ltr: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: this.showCondition,
      persistent: this.persistentCondition,
    };
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
    persistentCondition: {
      immediate: true,
      handler() {
        this.persistent = this.persistentCondition;
      },
    },
  },
  methods: {
    close() {
      if (!this.persistent) this.$emit("closed");
    },
  },
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.ltr {
  direction: ltr;
}
.close-btn {
  position: absolute;
  left: 10px;
  top: 10px;
}
</style>
