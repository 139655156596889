<template>
  <v-container>
    <v-row justify="center">
      <EducationList />
      <WorkExperiencesList />
      <VolunteerExperiencesList />
    </v-row>
  </v-container>
</template>

<script>
import WorkExperiencesList from "../../lists/WorkExperiencesList.vue";
import VolunteerExperiencesList from "../../lists/VolunteerExperiencesList.vue";
import EducationList from "../../lists/EducationList.vue";

export default {
  components: {
    WorkExperiencesList,
    VolunteerExperiencesList,
    EducationList
},
};
</script>

<style></style>
