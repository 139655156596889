import UserLayout from "@/core/layouts/UserLayout.vue";
import CreateResumeIntroPage from "../pages/CreateResumeIntroPage.vue";
import CreateResumePage from "../pages/CreateResumePage.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";

export default {
  path: "/create-resume",
  component: UserLayout,
  children: [
    {
      path: "/create-resume",
      name: "create_resume_intro",
      component: CreateResumeIntroPage,
      props: route => ({ isWebView: route.query.webView }),
      meta: { title: "إنشاء السيرة الذاتية" },
    },
    {
      path: "/create-resume/steps",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_resume_steps",
          component: CreateResumePage,
          props: route => ({ isWebView: route.query.webView }),
          meta: { title: "خطوات إنشاء السيرة الذاتية" },
        },
      ],
    }
  ],
};
