<template>
  <v-container v-if="resume.professionalSkills.length > 0" class="py-2">
    <BaseDivider :title="this.$t('professional_skills')" />
    <div v-for="(skill, i) in resume.professionalSkills" :key="i">
      <v-container>
        <v-row>
          <v-col cols="4">
            <b>{{ skill.title }}</b>
          </v-col>
          <v-col cols="6" align="start">
            <ProficiencyPercentage
            :value="skill.proficiency" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import ProficiencyPercentage from "./ProficiencyPercentage.vue";
import BaseDivider from "./BaseDivider.vue";

export default {
    computed: {
        ...mapState(useCreateResumeStore, ["resume", "selectedColor"]),
    },
    components: { ProficiencyPercentage, BaseDivider }
};
</script>

<style></style>
