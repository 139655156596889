<template>
  <v-col cols="12" md="4" align="center" justify="center">
    <AddButton :title="this.$t('projects')" @click="openDialog()" />
    <BaseDraggable v-model="resume.projects">
      <DeletableCard
        v-for="(project, i) in resume.projects"
        :key="i"
        :index="i"
        name="projects"
      >
        <p class="primary--text">{{ project.name }}</p>
        <p v-if="project.link" class="primary--text">{{ project.link }}</p>
        <p v-if="project.description" class="primary--text">{{ project.description }}</p>
        <p class="primary--text">
          {{ project.startDate }} |
          {{ project.present ? present : project.endDate }}
        </p>
      </DeletableCard>
    </BaseDraggable>
    <AddProjectDialog :showCondition="showDialog" @closed="closeDialog()" />
  </v-col>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";

import AddButton from "../buttons/AddButton.vue";
import DeletableCard from "../DeletableCard.vue";
import AddProjectDialog from "../dialogs/AddProjectDialog.vue";

export default {
  components: { AddButton, DeletableCard, AddProjectDialog },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
    present() {
      return this.$t("present");
    },
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style></style>
