// ** important! ph: stands for placeholder **
// ** important! q: stands for question **

// TODO: make sure to review all placeholders and find better explanations
// TODO: translate errors in form validation mixin

export const locale = {
  /** Arabic **/
  ar: {
    create_resume: "إنشاء السيرة الذاتية",
    preview_resume: "استعراض السيرة الذاتية",
    next: "التالي",
    male: "ذكر",
    female: "أنثى",
    previous: "السابق",
    add: "إضافة",
    present: "الوقت الحالي",
    required_field:"هذا الحقل مطلوب",
    required_personal_info:"عليك تعبئة حقول خطوة البيانات الشخصية ",//TODO find a better message?
    no:"لا",
    yes:"نعم",
    alert:"تنبيه",
    ok:"حسناً",
    profile_picture_not_saved:"لن يتم حفظ صورتك الشخصية مع السيرة الذاتية، لذا في حال رغبت بالتعديل عليها لاحقاً سيتوجب عليك إعادة رفع الصورة مرةً أخرى",

    // Stepper Steps
    personal_information: "المعلومات الشخصية",
    skills_and_languages: "المهارات واللغات",
    education_and_experiences: "التعليم والخبرات",
    certifications_and_projects: "الشهادات والمشاريع",
    links_and_references: "الروابط والمعارف",

    //preview resume step 
    pick_a_theme:"اختر سمة لسيرتك الذاتية",
    pick_a_color:"اختر لوناً رئيسياً لسيرتك الذاتية",
    preview:"استعراض",
    save_and_download:"حفظ وتحميل",
    change_language:"تغيير اللغة",

    // Titles
    professional_skills: "المهارات المهنية",
    languages: "اللغات",
    soft_skills: "المهارات الشخصية",
    certifications: "الشهادات",
    work_experiences: "خبرات العمل",
    volunteer_experiences: "الخبرات التطوعية",
    links: "روابط",
    references: "مراجع شخصية",
    educations: "التعليم",
    projects: "المشاريع",
    delete_conformation:"تأكيد الحذف",

    // Questions
    q_add_skill: "ما هي درجة إتقانك لهذه المهارة؟",
    q_add_language: "ما هي درجة إتقانك لهذه اللغة؟",
    delete_conformation_message: "هل أنت متأكد من أنك تريد حذف هذا العنصر؟",

    // Dialogs Titles
    add_professional_skill: "إضافة مهارة مهنية",
    add_soft_skill: "إضافة مهارة شخصية",
    add_language: "إضافة لغة",
    add_certificate: "إضافة شهادة",
    add_work_experience: "إضافة خبرة عمل",
    add_volunteer_experience: "إضافة خبرة تطوعية",
    add_project: "إضافة مشروع",
    add_reference: "إضافة معرفة",
    add_link: "إضافة رابط",
    add_education: "إضافة خبرة تعليمية",

    // Labels
    full_name: "الاسم الكامل",
    icon: "الأيقونة",
    date_of_birth: "تاريخ الميلاد",
    profile_picture: "الصورة الشخصية",
    gender: "الجنس",
    pitch_yourself: "لمحة عنك",
    phone_number: "رقم الهاتف",
    email: "البريد الالكتروني",
    address: "العنوان الحالي",
    skill_name: "اسم المهارة",
    language: "اللغة",
    description: "الوصف",
    link: "الرابط",
    name: "الاسم",
    certificate_name: "اسم الشهادة",
    certificate_issuer: "الجهة المانحة",
    issue_date: "تاريخ المنح",
    job_title: "المسمى الوظيفي",
    company: "جهة العمل",
    location: "مكان العمل",
    currently_working: "أعمل حالياً ضمن هذه الوظيفة",
    start_date: "تاريخ البدء",
    end_date: "تاريخ الانتهاء",
    role: "الدور",
    organization: "المؤسسة",
    currently_volunteering: "أنا متطوع حالياً ضمن هذا الدور",
    currently_working_project: "أنا أعمل حالياً على هذا المشروع",
    link_name: "اسم الرابط",
    contact_info: "معلومات الإتصال",
    reference_description: "وصف المعرفة",
    school: "الجامعة",
    field: "مجال الدراسة",
    currently_studying: "أنا أدرس حالياً ضمن هذه الجهة",
    from:"من",
    to:"إلى",

    // Options
    reference_description_options: ["مديري في العمل", "زميلي في العمل","معلم","صديق"], // TODO: Add remaining options

    // Placeholders
    ph_full_name: "الاسم الكامل",
    ph_date_of_birth: "تاريخ الميلاد",
    ph_profile_picture: "قم بتحميل صورتك الشخصية",
    ph_pitch_yourself: "صف نفسك ببضع كلمات (من أنت، ما هو شغفك، ما هو اختصاصك)",
    ph_phone_number: "رقم الهاتف",
    ph_email: "البريد الالكتروني",
    ph_address: "عنوان الاقامة الحالي",
    ph_pro_skill: "مثال: صيانة الحواسيب المحمولة،...",
    ph_soft_skill: "مثال: العمل تحت الضغط",
    ph_language: "اسم اللغة",
    ph_certificate_name: "مثال: البورد السوري",
    ph_certificate_issuer: "مثال: نقابة الأطباء السورية",
    ph_certificate_link: "رابط الشهادة",
    ph_certificate_description: "وصف الشهادة",
    ph_job_title: "مثال: مسؤول مبيعات، مبرمج",
    ph_company: "اسم الشركة التي تعمل لديها",
    ph_location: "مثال: دمشق",
    ph_job_description: "يمكنك هنا وصف المهام المُوكلة إليك",
    ph_role: "مثال: مدرب",
    ph_organization: "مثال: فريق الكريات الحمراء",
    ph_volunteer_description: "مثال: كتابة المحاضرات للطلاب",
    ph_project_name: "اسم المشروع",
    ph_project_link: "رابط المشروع",
    ph_project_description:
      "يمكنك هنا شرح الخدمات التي يقدمها المشروع، الفئة المستهدفة، ...",
    ph_link_name: "مثال: Linkedin",
    ph_link: "مثال: https://www.linkedin.com/in/username",
    ph_contact_info: "البريد الالكتروني أو رقم الهاتف",
    ph_school: "مثال: جامعة دمشق",
    ph_field: "مثال: إدارة أعمال",
  },

  /** English **/
  en: {
    create_resume: "Create Resume",
    preview_resume: "Preview Resume",
    next: "Next",
    male: "Male",
    female: "Female",
    previous: "Previous",
    add: "Add",
    present: "Present",
    required_field:"this field is required",
    required_personal_info:"You have to fill personal information step fields",
    no:"No",
    yes:"yes",
    alert:"Alert",
    ok:"OK",
    profile_picture_not_saved:"Your profile picture will not be saved with your resume, so if you want to modify it later, you will have to re-upload the picture again",

    // Stepper Steps
    personal_information: "Personal Information",
    skills_and_languages: "Skills and languages",
    education_and_experiences: "Education and Experiences",
    certifications_and_projects: "Certification and Projects",
    links_and_references: "Links and References",

    //preview resume step 
    pick_a_theme:"Pick a theme for your resume",
    pick_a_color:"Pick a main color for your resume",
    preview:"preview",
    save_and_download:"save & download",
    change_language:"Change Language",

    // Questions
    q_add_skill: "What is the degree of your mastery of this skill?",
    q_add_language: "What is the degree of your mastery of this language?",
    delete_conformation_message: "Are you sure you want to delete this item?",

    // Titles
    professional_skills: "Professional Skills",
    languages: "Languages",
    soft_skills: "Soft Skills",
    certifications: "Certifications",
    work_experiences: "Work Experiences",
    volunteer_experiences: "Volunteer Experiences",
    links: "Links",
    references: "References",
    educations: "Educations",
    projects: "Projects",
    delete_conformation: "Delete confirmation",

    // Dialogs Titles
    add_professional_skill: "Add professional skill",
    add_soft_skill: "Add soft skill",
    add_language: "Add language",
    add_certificate: "Add certificate",
    add_work_experience: "Add work experience",
    add_volunteer_experience: "Add volunteer experience",
    add_project: "Add project",
    add_reference: "Add reference",
    add_link: "Add link",
    add_education: "Add education",

    // Labels
    full_name: "Full Name",
    icon: "Icon",
    gender: "Gender",
    date_of_birth: "Date of Birth",
    profile_picture: "Profile Picture",
    pitch_yourself: "Pitch Yourself",
    phone_number: "Phone Number",
    email: "Email",
    address: "Current Address",
    skill_name: "Skill Name",
    language: "Language",
    description: "Description",
    link: "Link",
    name: "Name",
    certificate_name: "Certificate Name",
    certificate_issuer: "Certificate Issuer",
    issue_date: "Issue Date",
    job_title: "Job Title",
    company: "Company",
    location: "Location",
    currently_working: "I am currently working in this job",
    start_date: "Start Date",
    end_date: "End Date",
    role: "Role",
    link_name: "Link Name",
    organization: "Organization",
    currently_volunteering: "I am currently volunteering in this role",
    currently_working_project: "I am currently working on this project",
    contact_info: "Contact Info",
    reference_description: "References Description",
    school: "School",
    field: "Field of study",
    currently_studying: "I am currently studying here",
    from:"from",
    to:"to",

    // Options
    reference_description_options: ["My Boss", "Co-worker","Friend","Teacher"], // TODO: Add remaining options

    // Placeholders
    ph_full_name: "Full name",
    ph_date_of_birth: "Date of birth",
    ph_profile_picture: "Upload your profile picture",
    ph_pitch_yourself:
      "Describe yourself in a few words (who you are, Write about what you do and how you do it)",
    ph_phone_number: "Phone number",
    ph_email: "Email",
    ph_address: "Current residence address",
    ph_pro_skill: "Ex: Laptop maintenance, ...",
    ph_soft_skill: "Ex: Work under pressure",
    ph_language: "Language name",
    ph_certificate_name: "Ex: Syrian board",
    ph_certificate_issuer: "Ex: Syrian Doctors Syndicate",
    ph_certificate_link: "Certificate link",
    ph_certificate_description: "Certificate description",
    ph_job_title: "Ex: Sales Manager, Programmer",
    ph_company: "Name of the company you're working for",
    ph_location: "Ex: Damascus",
    ph_job_description: "Here you can describe the tasks assigned to you",
    ph_role: "Ex: Trainer",
    ph_organization: "Ex: RBCs",
    ph_volunteer_description: "Ex: Writing lectures for students",
    ph_project_name: "Project name",
    ph_project_link: "Project link",
    ph_project_description:
      "Here you can explain the project services and the stakeholders",
    ph_link_name: "Ex: Linkedin",
    ph_link: "Ex: https://www.linkedin.com/in/username",
    ph_contact_info: "Email or phone number",
    ph_school: "Ex: Damascus University",
    ph_field: "Ex: Business",
  },
};
