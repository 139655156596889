<template>
  <v-container fluid style="position: relative">
    <v-row align="center" justify="center">
      <!--TODO unimportant suggestion: what about cutting the illustration they gave use into two lines of diamonds and use it instead of this? donee, TODO: cut the diamonds in better way-->
      <v-container fluid class="decoration"></v-container>
      <v-col cols="11" md="6">
        <v-carousel
          v-model="activeSlide"
          hide-delimiters
          cycle
          height="auto"
          :show-arrows="false"
        >
          <v-carousel-item v-for="(advice, i) in ethicalAdvices" :key="i">
            <v-container>
              <v-card
                min-height="100px"
                class="rounded-xl d-flex align-center justify-center"
              >
                <v-row align="center" justify="center">
                  <v-col align="center" justify="center">
                    <p class="ma-0 pa-2 secondary--text">
                      {{ advice.content }}
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useHomeStore } from "../store";
import { mapActions, mapState } from "pinia";

export default {
  data() {
    return {
      activeSlide: 0,
    };
  },
  computed: {
    ...mapState(useHomeStore, ["ethicalAdvices"]),
  },
  methods: {
    ...mapActions(useHomeStore, ["getEthicalAdvices"]),
  },
  mounted() {
    this.getEthicalAdvices();
  },
};
</script>

<style scoped>
.decoration {
  position: absolute;
  min-height: 80px;
  background-image: url("@/assets/illustrations/home/ethical_advice_decoration.svg");
  background-repeat: repeat-x;
}
.v-card {
  background-image: url(@/assets/illustrations/home/ethical_advice_bg.jpg); /*url(@/assets/illustrations/home/ethical_advice_bg1.jpg); !important change <p> text color to lightSecondary*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
