<template>
  <BaseDialog
    :ltr="selectedLanguage == 'en'"
    :showCondition="show"
    @closed="closeDialog()"
  >
    <template v-slot:title> {{ title }} </template>
    <template v-slot:body>
      <v-form ref="form">
        <BaseTextField
          v-model="experience.role"
          :rules="[requiredRule]"
          :label="role"
          :placeholder="rolePlaceholder"
        >
        </BaseTextField>

        <BaseTextField
          v-model="experience.organization"
          :rules="[requiredRule]"
          :label="organization"
          :placeholder="organizationPlaceholder"
        >
        </BaseTextField>

        <BaseTextField
          v-model="experience.description"
          :label="description"
          :placeholder="volunteerDescriptionPlaceholder"
        >
        </BaseTextField>

        <v-checkbox
          class="ma-0 pa-0"
          v-model="experience.present"
          :label="currentlyVolunteering"
        ></v-checkbox>

        <DateField
          v-model="experience.startDate"
          :rules="[requiredRule]"
          :label="startDate"
          @changed="(date) => updateStartDate(date)"
        />

        <div v-show="!experience.present">
          <DateField
            v-model="experience.endDate"
            :label="endDate"
            :rules="experience.present ? [] : [requiredRule]"
            @changed="(date) => updateEndDate(date)"
          />
        </div>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-btn color="accent" rounded @click="validateThenAddExperience()">
        {{ add }}
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import DateField from "../DateField.vue";
import VolunteerExperience from "../../models/VolunteerExperience.js";
import ResumeValidationMixin from "../ResumeValidationMixin.vue";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import { mapWritableState } from "pinia";

export default {
  mixins:[ResumeValidationMixin],
  components: { DateField },
  data() {
    return {
      show: this.showCondition,
      experience: new VolunteerExperience(),
    };
  },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume", "selectedLanguage"]),
    title() {
      return this.$t("add_volunteer_experience");
    },
    add() {
      return this.$t("add");
    },
    role() {
      return this.$t("role");
    },
    startDate() {
      return this.$t("start_date");
    },
    endDate() {
      return this.$t("end_date");
    },
    organization() {
      return this.$t("organization");
    },
    currentlyVolunteering() {
      return this.$t("currently_volunteering");
    },
    description() {
      return this.$t("description");
    },
    rolePlaceholder() {
      return this.$t("ph_role");
    },
    organizationPlaceholder() {
      return this.$t("ph_organization");
    },
    volunteerDescriptionPlaceholder() {
      return this.$t("ph_volunteer_description");
    },
  },
  methods: {
    updateStartDate(date) {
      this.experience.startDate = date;
    },
    updateEndDate(date) {
      this.experience.endDate = date;
    },
    reset() {
      this.experience = new VolunteerExperience();
      this.$refs.form.reset();
    },
    closeDialog() {
      this.reset();
      this.show = false;
      this.$emit("closed");
    },
    validateThenAddExperience() {
      if (this.$refs.form.validate()) {
        this.resume.volunteerExperiences.push(this.experience);
        this.closeDialog();
      }
    },
  },
};
</script>
