<template>
  <div>
    <v-tooltip bottom :open-delay="100">
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          v-on="{ ...tooltip }"
          :class="[
            'mx-2 floating-btn-1',
            {
              'pos-sm': $vuetify.breakpoint.smAndDown,
              'pos-md': $vuetify.breakpoint.mdAndUp,
            },
          ]"
          fab
          dark
          small
          color="accent"
          @click="redirectToFAQs()"
          ><v-icon>mdi-frequently-asked-questions</v-icon></v-btn
        >
      </template>
      <span> الأسئلة الشائعة</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToFAQs() {
      this.$router.push({ name: "FAQs" });
    },
  },
};
</script>

<style scoped>
.floating-btn-1 {
  position: fixed;
  z-index: 2;
}
.pos-md {
  left: 50px;
  bottom: 100px;
}
.pos-sm {
  left: 1px;
  bottom: 75px;
}
</style>
