<template>
  <BaseCareerTest context="tp_application">
    <!--Previous result page-->
    <template v-slot:PreviousResult>
      <v-container class="py-10"><!-- v-if="!testStarted"-->
        <v-row justify="center">
          <h2 class="mb-5 text-center">
            نموذج البركة المطور لمقياس هولاند لتحديد الميول والقدرات المهنية
          </h2>
          <v-row justify="center" class="mt-5">
            <v-col align-self="center" cols="12" sm="8" md="5">
              <div class="mb-5">
                قمت بالفعل بإجراء اختبار سابق، هل تود اعتماد نتيجة الاختبار
                السابق، أم تريد إعادة الاختبار من جديد؟
              </div>
              <v-btn id="confirm-test-result-btn" color="accent" rounded dark @click="emitCompleted()">اعتماد النتيجة
                السابقة</v-btn>
              <v-btn color="lightNeutral" rounded class="mr-5" @click="redoTest()">إعادة الاختبار</v-btn>
            </v-col>
            <v-col cols="0" md="2" class="d-none d-md-flex">
              <v-img src="@/assets/illustrations/training_programs/career_aptitude_test.png"></v-img>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </template>
    <!--new result page actions-->
    <template v-slot:resultActions>
      <div class="my-10">
        <TestResultTemplate />
        <v-btn @click="emitCompleted()" color="lightNeutral" rounded class="mr-3 px-5">إرسال طلب التسجيل</v-btn>
        <v-btn @click="exportResult()" color="accent" class="mr-3 my-3" rounded dark>
          تصدير النتيجة
          <v-icon class="mr-3"> mdi-file-move-outline</v-icon>
        </v-btn>
      </div>
    </template>
  </BaseCareerTest>
</template>

<script>
import BaseCareerTest from "@/modules/career_aptitude_test/components/BaseCareerTest.vue";
import TestResultTemplate from "@/modules/career_aptitude_test/components/TestResultTemplate.vue";
import { useCareerAptitudeTestStore } from "@/modules/career_aptitude_test/store/CareerAptitudeTestStore";
import { mapActions, mapState } from "pinia";

import html2pdf from "html2pdf.js";
import { useGlobalStore } from "@/store/GlobalStore";
const globalStore = useGlobalStore();

export default {
  components: { BaseCareerTest, TestResultTemplate },
  computed: {
    ...mapState(useCareerAptitudeTestStore, ["testResultId"]),
  },
  methods: {
    emitCompleted() {
      this.$emit("completed", this.testResultId);
    },
    ...mapActions(useCareerAptitudeTestStore, ["resetTestResultAndRedoTest"]),
    redoTest() {
      this.resetTestResultAndRedoTest();
    },
    exportResult() {
      globalStore.changeLoadingStatus(true);
      const element = document.getElementById("result-template");
      var clonedElement = element.cloneNode(true);
      clonedElement.style.display = "block";
      html2pdf()
        .set({
          filename: "نتيجة مقياس الميول المهني" + ".pdf",
          image: { type: "png", quality: 0.4 },
          html2canvas: { scale: 2, letterRendering: true },
          jsPDF: { unit: "pt", orientation: "portrait", compress: true },
          pagebreak: { mode: ["avoid-all"] },
        })
        .from(clonedElement)
        .save()
        .then(() => {
          globalStore.changeLoadingStatus(false);
        });
    },
  },
};
</script>