<template>
  <v-row class="my-5 mx-5">
    <h3>
      <span class="primary--text"> {{ this.title }}: </span>
      {{ this.description }}
    </h3>
  </v-row>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
  },
};
</script>
