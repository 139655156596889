<template>
  <div>
    <!--CASE 1: a previous test result exists-->
    <slot v-if="showResult" name="PreviousResult"></slot>
    <!--CASE 2: no previous test result-->
    <div v-if="showTest">
      <!--TEST intro-->
      <v-container v-if="!testStarted">
        <v-row justify="center">
          <h2 class="mb-5 secondary--text text-center">
            نموذج "البركة" لتحديد الميول والقدرات المهنية
          </h2>
          <v-row justify="center" class="mt-5">
            <v-col align-self="center" cols="10" sm="8" md="5">
              <div>
                هذا الاختبار هو نموذج لمقياس مهني أكاديمي تم تطويره في جمعية
                البركة للتنمية الاجتماعية اعتماداً على نظرية جون هولاند للأنماط
                المهنية المعتمد عالمياً بما يناسب واقع المجتمع وسوق العمل في
                بلادنا العربية وباللغة المناسبة، وبطريقة جديدة مبتكرة تبسِّط
                طريقة حساب نتيجة المقياس. لتعرف النمط المهني الأنسب لشخصيتك
                بدقة، عليك الإجابة على الأسئلة الآتية حسب الواقع العملي لا حسب
                ما تظن أنه الصواب أو المثالي، والمطلوب منك الإجابة بسرعة
                وبتلقائية دون تردد علماً أنه ليست هناك إجابات صحيحة أو خاطئة.
              </div>
              <NextButton class="mt-5" @click="testStarted = true" />
            </v-col>
            <v-col align-self="start" cols="0" md="5" lg="4" class="d-none d-md-flex">
              <v-img transition="scale-transition" contain
                src="@/assets/illustrations/training_programs/career_aptitude_test.svg"></v-img>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
      <!--TEST QS-->
      <div v-else>
        <v-container v-if="!testFinished">
          <v-row justify="center">
            <v-col cols="10" sm="7" md="6" align="center" height="100">
              <v-progress-linear class="mb-15" color="accent" height="20" :value="getTestProgress">
                <template v-slot:default>
                  <small>{{ currentTestIndex + 1 }} من {{ testQs.length }} سؤال</small>
                </template>
              </v-progress-linear>
              <h2 class="mb-5 secondary--text">
                {{ testQs[currentTestIndex].question }}
              </h2>
              <v-radio-group mandatory v-model="selectionValue" @change="pushAnswer($event)" row>
                <!--Attention the order of these two radio options DOES MATTER don't change this order-->
                <v-radio color="accent" label="لا" :value="false"></v-radio>
                <v-spacer></v-spacer>
                <v-radio color="accent" label="نعم" :value="true"></v-radio>
              </v-radio-group>
              <NextButton @click="getNextQ()" />
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!--TEST results-->
      <div v-if="testFinished">
        <InvalidTestResult v-if="testIsInvalid" />
        <ValidTestResult v-else>
          <template v-slot:actions>
            <slot name="resultActions"></slot>
          </template>
        </ValidTestResult>
      </div>
    </div>
  </div>
</template>

<script>
import NextButton from "@/modules/training_program_application/components/buttons/NextButton.vue";
import { useCareerAptitudeTestStore } from "../store/CareerAptitudeTestStore";
import { mapActions, mapWritableState } from "pinia";
import InvalidTestResult from "./InvalidTestResult.vue";
import ValidTestResult from "./ValidTestResult.vue";
import { EventBus } from "@/main";

const CareerAptitudeTestStore = useCareerAptitudeTestStore();

export default {
  props: {
    context: {
      type: String,
      default: "aptitude_test",
    },
  },
  mounted() {
    this.fetchCareerTestResult(this.context)
      .then()
      .catch((err) => {
        err;
      });
  },
  computed: {
    ...mapWritableState(useCareerAptitudeTestStore, [
      "currentTestIndex",
      "selectionValue",
      "showResult",
      "showTest",
      "testAnswers",
      "testFinished",
      "testQs",
      "testResult",
      "testStarted",
      "testIsInvalid",
      "testResultId",
    ]),
    getTestProgress() {
      if (this.testFinished) return 100;
      else return (this.currentTestIndex * 100) / this.testQs.length;
    },
  },
  methods: {
    ...mapActions(useCareerAptitudeTestStore, [
      "fetchCareerTestResult",
      "postCareerAptitudeTestAnswers",
    ]),
    getNextQ() {
      //if there is more questions
      if (this.currentTestIndex != this.testQs.length - 1) {
        this.currentTestIndex++;
        this.selectionValue = null;
      } //case no more question send answers
      else {
        this.testFinished = true;
        this.postCareerAptitudeTestAnswers(this.context)
          .then(() =>
            //emit test submitted (so the user can leave the page without alerting)
            EventBus.$emit("testSubmitted")
          )
          .catch((err) => {
            err;
          });
      }
    },
    pushAnswer($event) {
      //emit form changed
      EventBus.$emit("formChanged");
      //ONLY PUSH "true" answers
      if ($event == true) {
        this.testAnswers.push(this.testQs[this.currentTestIndex].id);
      }
      // var answerIndex = this.testAnswers.findIndex(el => el.question_id === this.testQs[this.currentTestIndex].id);
      // if (answerIndex != -1)
      //     this.testAnswers[answerIndex].answer = $event;
      // else
      //     this.testAnswers.push({ question_id: this.testQs[this.currentTestIndex].id, answer: $event })
    },
  },
  components: { NextButton, InvalidTestResult, ValidTestResult },
  destroyed() {
    CareerAptitudeTestStore.$reset();
  },
};
</script>
