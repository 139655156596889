<template>
  <div>
    <!-- TODO: check if advices list is empty?? -->
    <EthicalAdvices />
    <OurServices />
    <!--floating buttons (feedback/FAQs) -->
    <FAQFloatingButton />
    <FeedbackFloatingButton />
  </div>
</template>

<script>
import EthicalAdvices from "../components/EthicalAdvices.vue";
import FAQFloatingButton from "../components/FAQFloatingButton.vue";
import FeedbackFloatingButton from "../components/FeedbackFloatingButton.vue";
import OurServices from "../components/OurServices.vue";

export default {
  components: {
    FAQFloatingButton,
    FeedbackFloatingButton,
    EthicalAdvices,
    OurServices,
  },
};
</script>

<style scoped></style>
