<template>
  <BaseDialog
    :ltr="selectedLanguage == 'en'"
    :showCondition="show"
    @closed="closeDialog()"
  >
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <v-form ref="form">
        <BaseTextField
          v-model="certificate.name"
          :label="certificateName"
          :rules="[requiredRule]"
          :placeholder="certificateNamePlaceholder"
        ></BaseTextField>

        <BaseTextField
          v-model="certificate.issuer"
          :rules="[requiredRule]"
          :label="certificateIssuer"
          :placeholder="certificateIssuerPlaceholder"
        >
        </BaseTextField>

        <BaseTextArea
          v-model="certificate.description"
          :label="description"
          :placeholder="certificateDescriptionPlaceholder"
        ></BaseTextArea>

        <BaseTextField
          v-model="certificate.link"
          :label="link"
          :placeholder="certificateLinkPlaceholder"
        ></BaseTextField>

        <DateField
          v-model="certificate.issueDate"
          :rules="[requiredRule]"
          :label="issueDate"
          :placeholder="issueDate"
          @changed="(date) => updateIssueDate(date)"
        />
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn color="accent" rounded @click="validateThenAddCertificate()">
        {{ add }}
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import DateField from "../DateField.vue";
import Certificate from "../../models/Certificate";

import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import ResumeValidationMixin from "../ResumeValidationMixin.vue";

export default {
  mixins:[ResumeValidationMixin],
  components: { DateField },
  data() {
    return {
      show: this.showCondition,
      certificate: new Certificate(),
    };
  },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume","selectedLanguage"]),
    add() {
      return this.$t("add");
    },
    title() {
      return this.$t("add_certificate");
    },
    link() {
      return this.$t("link");
    },
    issueDate() {
      return this.$t("issue_date");
    },
    description() {
      return this.$t("description");
    },
    certificateName() {
      return this.$t("certificate_name");
    },
    certificateIssuer() {
      return this.$t("certificate_issuer");
    },
    certificateNamePlaceholder() {
      return this.$t("ph_certificate_name");
    },
    certificateIssuerPlaceholder() {
      return this.$t("ph_certificate_issuer");
    },
    certificateLinkPlaceholder() {
      return this.$t("ph_certificate_link");
    },
    certificateDescriptionPlaceholder() {
      return this.$t("ph_certificate_description");
    },
  },
  methods: {
    updateIssueDate(date) {
      this.certificate.issueDate = date;
    },
    reset() {
      this.certificate = new Certificate();
      this.$refs.form.reset();
    },
    closeDialog() {
      this.reset();
      this.show = false;
      this.$emit("closed");
    },
    validateThenAddCertificate() {
      if (this.$refs.form.validate()) {
        this.resume.certificates.push(this.certificate);
        this.closeDialog();
      }
    },
  },
};
</script>
