<template>
  <v-col cols="12" sm="6" lg="4">
    <v-card hover shaped @click="pushDetailsPage">
      <v-img
        v-if="obj.coverPath != undefined"
        :src="obj.coverPath"
        lazy-src="@/assets/logos/brown-logo.svg"
        height="250px"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular color="accent" indeterminate></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-img
        v-else
        src="@/assets/logos/brown-logo.svg"
        contain
        height="250px"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="9" style="position: relative">
              <p>
                <span class="card_title primary--text"> {{ obj.title }} </span>
                <br />
                <span class="card_text"> {{ obj.description }} </span>
                <br />
              </p>
            </v-col>
            <v-col class="d-flex justify-center" cols="3">
              <v-icon x-large color="primary">mdi-chevron-left</v-icon>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<style scoped>
.card_title {
  position: absolute;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: bold;
  font-size: 18px;
}
.card_text {
  position: absolute;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

<script>
export default {
  methods: {
    pushDetailsPage() {
      this.$router.push(
        this.isNews ? "/news/" + this.obj.id : "/projects-fair/" + this.obj.id
      );
    },
  },
  props: {
    obj: Object,
    isNews: Boolean,
  },
};
</script>
