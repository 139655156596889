<template>
  <v-container>
    <v-row justify="center">
      <LinksList />
      <ReferencesList />
    </v-row>
  </v-container>
</template>

<script>
import LinksList from "../../lists/LinksList.vue";
import ReferencesList from "../../lists/ReferencesList.vue";

export default {
  components: {
    LinksList,
    ReferencesList,
  },
};
</script>

<style></style>
