<template>
  <v-btn
    @click="changeLoginDialogState(true,false)"
    text
    outlined
    rounded
    small
    color="primary"
    >تسجيل الدخول
  </v-btn>
</template>

<script>
import { mapActions } from "pinia";
import { useAuthStore } from "@/modules/auth/store/AuthStore";
export default {
  methods: {
    ...mapActions(useAuthStore, ["changeLoginDialogState"]),
  },
};
</script>
