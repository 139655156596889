<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" align="center" class="my-auto">
        <BaseTransitionWrapper type="right">
          <h1 class="primary--text">من نحن</h1>
          <p>
            تأسست جمعية البركة للتنمية الاجتماعية رسميا<br />
            برقم 1588 بتاريخ 16-9-2010 م، وهي منظمة أهلية غير ربحية.
          </p>
          <h1 class="primary--text mt-10">رؤيتنا</h1>
          <p>
            تتلخص رؤية الجمعية في الوصول إلى سوق عمل ناجح <br />يحكِّم المعايير
            الاخلاقية.
          </p>
        </BaseTransitionWrapper>
      </v-col>
      <v-col class="d-none d-sm-inline" cols="6" align="center">
        <BaseTransitionWrapper type="left">
          <v-img class="logo" src="@/assets/logos/vector-logo.svg" contain />
        </BaseTransitionWrapper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
.logo {
  width: 300px;
  height: 300px;
}
</style>
