<template>
  <v-stepper vertical v-model="currentStepNum" non-linear>
    <v-stepper-step color="primary" :complete="currentStepNum > 1" step="1">
      البيانات الشخصية
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-container>
        <h4 class="secondary--text mb-2">
            قم بتعبئة معلوماتك الشخصية
        </h4>
        <v-row justify="center">
          <v-col align-self="center" cols="12" md="7" sm="9">
            <FormFactory
              ref="form_step_1"
              :fields="getFirstStepFields"
              formReference="form_step_1"
            />
          </v-col>
        </v-row>
      </v-container>
      <NextButton @click="validateCurrentStepThenGoToTarget(2)" />
    </v-stepper-content>

    <v-stepper-step color="primary" :complete="currentStepNum > 2" step="2">
      الدراسة والعمل
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-container>
        <h4 class="secondary--text mb-2">
          قم بتعبئة معلومات الدراسة والعمل 
        </h4>
        <v-row justify="center">
          <v-col align-self="center" cols="12" md="7" sm="9">
            <FormFactory
              ref="form_step_2"
              :fields="getSecondStepFields"
              formReference="form_step_2"
            />
          </v-col>
        </v-row>
      </v-container>
      <PreviousButton @click="goToPreviousStep()" />
      <NextButton @click="validateCurrentStepThenGoToTarget(3)" />
    </v-stepper-content>

    <v-stepper-step color="primary" step="3">
      نوعية الاستفادة
    </v-stepper-step>
    <v-stepper-content step="3">
      <v-container>
        <v-row justify="center">
          <v-row justify="center">
            <v-col cols="12" sm="10" md="6">
              <h4 class="secondary--text mb-2">
                 نوعية الاستفادة
              </h4>
              <p>
                بحكم كون الجمعية مؤسسة تنموية خيرية...تقدم الجمعية الدورات
                التدريبية مجاناً للأشخاص المستحقين مادياً لهذه الخدمة بعد أن
                تقوم الجمعية بإجراء دراسة الحالة المادية للمتقدمين، كما تقدمها
                بأجور مخفضة (الكلفة فقط) لبقية الأشخاص الراغبين فيها (على أن
                الأولوية للأشخاص المستحقين للدورات المجانية) فإذا كنت تعتقد أنك
                من مستحقي الدورات المجانية وترغب بالاستفادة منها يمكنك التقدم
                للدورات المجانية
              </p>
            </v-col>
            <v-col align-self="center" cols="6" class="d-none d-md-inline">
              <v-img
                contain
                :aspect-ratio="16 / 9"
                src="@/assets/illustrations/training_programs/benefit_type.svg"
              ></v-img>
            </v-col>
          </v-row>
          <v-col align-self="center" cols="12" md="7" sm="9">
            <FormFactory
              ref="form_step_3"
              :fields="getThirdStepFields"
              formReference="form_step_3"
            />
          </v-col>
        </v-row>
      </v-container>
      <PreviousButton @click="goToPreviousStep()" />
      <NextButton @click="validateCurrentStepThenGoToTarget(3)" />
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import { Notify } from "@/core/classes/notify/Notify";
import NextButton from "../buttons/NextButton.vue";
import FormFactory from "../FormFactory.vue";
import PreviousButton from "../buttons/PreviousButton.vue";
import { mapActions, mapState } from "pinia";
import { useApplicationFormStore } from "../../store/ApplicationFormStore";
export default {
  components: {
    NextButton,
    FormFactory,
    PreviousButton,
  },
  data() {
    return {
      currentStepNum: 1,
      fieldsData: {},
    };
  },
  mounted() {
    this.fetchTrainingProgramQuestions()
      .then()
      .catch((err) => {
        err;
      });
  },
  computed: {
    ...mapState(useApplicationFormStore, ["trainingProgramQuestions"]),
    getFirstStepFields() {
      return this.trainingProgramQuestions.filter(
        (field) => field.stepNumber == 1
      );
    },
    getSecondStepFields() {
      return this.trainingProgramQuestions.filter(
        (field) => field.stepNumber == 2
      );
    },
    getThirdStepFields() {
      return this.trainingProgramQuestions.filter(
        (field) => field.stepNumber == 3
      );
    },
  },
  methods: {
    ...mapActions(useApplicationFormStore, ["fetchTrainingProgramQuestions"]),
    validateCurrentStepThenGoToTarget(targetStep) {
      var stepIsValid = this.$refs["form_step_" + this.currentStepNum].$refs[
        "form_step_" + this.currentStepNum
      ].validate();
      if (stepIsValid) {
        this.currentStepNum != 3
          ? (this.currentStepNum = targetStep)
          : this.$emit("completed");
      } else {
        Notify.showMessage(
          "error",
          "الرجاء التأكد من ملء جميع البيانات المطلوبة"
        );
      }
    },
    goToPreviousStep() {
      this.currentStepNum--;
    },
  },
};
</script>
