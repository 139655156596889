<template>
  <BaseDecoratorWrapper>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="9" lg="7">
          <v-row justify="center" class="mb-5">
            <v-icon color="primary" class="ml-5"
              >mdi-frequently-asked-questions</v-icon
            >
            <div style="font-size: 25px">أسئلة شائعة والإجابات عنها</div>
          </v-row>
          <!--filter tags-->
          <v-row justify="center" class="mb-5">
            <BaseFilterChips
              @filtered="(selectedTag) => updateFilterState(selectedTag)"
              :tags="tags"
            />
          </v-row>
          <!--FAQ items-->
          <div
            class="mb-5"
            v-for="faq in paginatedFAQs.collection"
            :key="faq.id"
          >
            <FAQItem :faq="faq" />
          </div>
          <v-row
            justify="center"
            class="mb-5"
            v-if="paginatedFAQs.collection.length == 0 && !isLoading"
          >
            لم يتم العثور على نتائج......
          </v-row>
          <!--next btn-->
          <v-btn
            v-else
            color="accent"
            :disabled="paginatedFAQs.isLastPage"
            rounded
            elevation="8"
            @click="fetchNextPage()"
          >
            <v-icon>mdi-arrow-left</v-icon>
            التالي
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </BaseDecoratorWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import FAQItem from "../components/FAQItem.vue";
import { useFAQsStore } from "../store/FAQsStore";
import { useGlobalStore } from "@/store/GlobalStore"; //TODO put it in a global mixin?

const FAQStore = useFAQsStore();

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(useFAQsStore, ["paginatedFAQs", "tags"]),
    ...mapState(useGlobalStore, ["isLoading"]),
  },
  components: { FAQItem },
  methods: {
    ...mapActions(useFAQsStore, [
      "fetchPaginatedFAQs",
      "fetchFAQsTags",
      "fetchNextPage",
      "setFilterStateAndRefetch",
    ]),
    updateFilterState(selectedTag) {
      this.setFilterStateAndRefetch(selectedTag);
    },
  },
  mounted() {
    this.fetchFAQsTags()
      .then()
      .catch((err) => err);
    this.fetchPaginatedFAQs()
      .then()
      .catch((err) => err);
  },
  destroyed() {
    FAQStore.$reset();
  },
};
</script>

<style scoped></style>
