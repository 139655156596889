<template>
  <v-container>
    <DetailsPage v-if="!fetchingData" :obj="project" :isNews="false" />
  </v-container>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useProjectsFairStore } from "../store/ProjectsFairStore";

import DetailsPage from "@/core/components/news_and_projects/DetailsPage.vue";

export default {
  components: { DetailsPage },
  data() {
    return {
      fetchingData: true,
    };
  },
  computed: {
    ...mapState(useProjectsFairStore, ["project"]),
  },
  methods: {
    ...mapActions(useProjectsFairStore, ["fetchProject"]),
  },
  mounted() {
    this.fetchProject(this.$route.params.id)
      .then(() => (this.fetchingData = false))
      .catch((err) => console.error(err));
  },
};
</script>

<style scoped></style>
