<template>
  <v-btn
    @click="changeLogoutDialogVisibility()"
    text
    outlined
    rounded
    color="primary"
    >تسجيل الخروج
  </v-btn>
</template>

<script>
import { useAuthStore } from "@/modules/auth/store/AuthStore";
import { mapState, mapActions } from "pinia";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(useAuthStore, ["username"])
  },
  methods: {
    ...mapActions(useAuthStore, ["changeLogoutDialogVisibility"])
  }
};
</script>
