export default class Reference {
  name;
  jobTitle;
  description;
  contactInfo;
  constructor(data) {
    this.name = data?.name;
    this.jobTitle = data?.jobTitle;
    this.description = data?.description;
    this.contactInfo = data?.contactInfo;
  }
}
