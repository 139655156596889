<template>
  <v-col cols="12" md="4" align="center" justify="center">
    <AddButton :title="this.$t('educations')" @click="openDialog()" />
    <BaseDraggable v-model="resume.educations">
      <DeletableCard
        v-for="(education, i) in resume.educations"
        :key="i"
        :index="i"
        name="educations"
      >
        <p class="primary--text">{{ education.field }}</p>
        <p class="primary--text">{{ education.school }}</p>
        <p class="primary--text">
          {{ education.startDate }} |
          {{ education.present ? present : education.endDate }}
        </p>
      </DeletableCard>
    </BaseDraggable>
    <AddEducationDialog :showCondition="showDialog" @closed="closeDialog()" />
  </v-col>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";

import AddButton from "../buttons/AddButton.vue";
import DeletableCard from "../DeletableCard.vue";
import AddEducationDialog from "../dialogs/AddEducationDialog.vue";

export default {
  components: { AddButton, DeletableCard, AddEducationDialog },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
    present() {
      return this.$t("present");
    },
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style></style>
