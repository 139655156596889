<template>
  <v-slide-group multiple show-arrows>
    <v-slide-item class="mx-3" v-for="job in jobSuggestions" :key="job.id">
      <JobCardItem :job="job" />
    </v-slide-item>
  </v-slide-group>
</template>

<script>
import JobCardItem from "./JobCardItem.vue";

export default {
  props: {
    jobSuggestions: Array,
  },
  components: { JobCardItem },
};
</script>
