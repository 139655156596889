<template>
    <div>
        <h2 class="mb-5 text-center">تم تسجيل طلبك</h2>
        <v-row justify="center" class="mt-5">
            <v-col align="center" align-self="center" cols="9" md="5">
                <div class="mb-10">
                    تم استلام طلبكم بنجاح، سيتم التواصل مع الطلبات المكتملة المعلومات والتي تحقق شروط البرنامج، المدة المتوقعة للانتظار شهرين
                    <br>
                    (بإمكانكم الاستفسار عن حالة الطلب بالاتصال على الرقم 2232378)
                </div>
                <v-btn color="accent" rounded dark @click="redirectToHome()">عودة للصفحة الرئيسية</v-btn>
            </v-col>
            <v-col cols="0" md="5" class="d-none d-md-flex">
                <v-img src="@/assets/illustrations/training_programs/app_sent.svg"></v-img>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    methods: {
        redirectToHome() {
            this.$router.push('/');
        }
    }
}
</script>