<template>
  <BaseAuthenticationWrapper>
  <BaseDecoratorWrapper>
    <v-container>
      <v-row justify="center">
        <v-col cols="11" align-items="center">
          <v-row>
            <h1 class="secondary--text">فرص العمل التي تقدمت عليها</h1>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="6"
              lg="4"
              align="center"
              v-for="job in paginatedAppliedJobs.collection"
              :key="job.id"
            >
              <AppliedJobCard :job="job" />
            </v-col>

            <!-- No data -->
            <v-col>
              <EmptyPaginationCollection :paginatedCollection="paginatedAppliedJobs" />
            </v-col>
          </v-row>
          <v-row>
            <!-- previous button -->
            <PaginationPreviousButton
              :paginatedCollection="paginatedAppliedJobs"
              :onclick="fetchPreviousPage"
            />
            <!-- Next button -->
            <PaginationNextButton
              class="mr-5"
              :paginatedCollection="paginatedAppliedJobs"
              :onclick="fetchNextPage"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </BaseDecoratorWrapper>
</BaseAuthenticationWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import AppliedJobCard from "../components/AppliedJobCard.vue";
import PaginationNextButton from "@/core/components/pagination/PaginationNextButton.vue";
import PaginationPreviousButton from "@/core/components/pagination/PaginationPreviousButton.vue";
import EmptyPaginationCollection from "@/core/components/pagination/EmptyPaginationCollection.vue";
import { useAppliedJobOpportunitiesStore } from "../store/AppliedJobOpportunitiesStore";

const appliedJobOpportunitiesStore = useAppliedJobOpportunitiesStore();

export default {
  components: {
    AppliedJobCard,
    PaginationNextButton,
    PaginationPreviousButton,
    EmptyPaginationCollection,
  },
  mounted() {
    this.fetchAppliedJobs()
      .then()
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useAppliedJobOpportunitiesStore, ["paginatedAppliedJobs"]),
  },
  methods: {
    ...mapActions(useAppliedJobOpportunitiesStore, [
      "fetchAppliedJobs",
      "fetchNextPage",
      "fetchPreviousPage",
    ]),
  },
  destroyed() {
    appliedJobOpportunitiesStore.$reset();
  },
};
</script>
