import { defineStore } from "pinia";

import CareerAptitudeTestQuestion from "@/core/classes/models/CareerAptitudeTestQuestion";
import CareerAptitudeTestResult from "@/core/classes/models/CareerAptitudeTestResult";
import CareerAptitudeTestService from "../services/CareerAptitudeTestService";
import BaseStore from "@/core/classes/base/BaseStore";

export const useCareerAptitudeTestStore = defineStore(
  "careerAptitudeTestStore",
  {
    state: () => ({
      showResult: false,
      showTest: true,
      testResult: new CareerAptitudeTestResult(),
      testStarted: false,
      testFinished: false,
      testAnswers: [],
      selectionValue: null,
      currentTestIndex: 0,
      testIsInvalid: false,
      testQs: [],
      testResultId: 0,
    }),
    getters: {},
    actions: {
      resetTestResultAndRedoTest() {
        this.testResult = new CareerAptitudeTestResult();
        this.testStarted = false;
        this.testFinished = false;
        this.testAnswers = [];
        this.selectionValue = null;
        this.currentTestIndex = 0;
        this.testIsInvalid = false;
        this.showResult = false;
        this.showTest = true;
        this.testResultId = 0;
        this.fetchCareerTestQuestions();
      },
      fetchCareerTestResult(context) {
        return BaseStore.promiseHandler(
          () => CareerAptitudeTestService.getCareerTestResult(context),
          (data) => {
            var testIsTakenBefore = data.data.test_is_taken_before;
            if (testIsTakenBefore) {
              this.testResult = new CareerAptitudeTestResult(
                data.data.testResult
              );
              this.showResult = true;
              this.showTest = false;
            } else {
              this.fetchCareerTestQuestions();
            }
          }
        );
      },
      fetchCareerTestQuestions() {
        return BaseStore.promiseHandler(
          () => CareerAptitudeTestService.getTestQuestions(),
          (data) => {
            data.data.map((ctq) => {
              this.testQs.push(new CareerAptitudeTestQuestion(ctq));
            });
          }
        );
      },
      postCareerAptitudeTestAnswers(context) {
        return BaseStore.promiseHandler(
          () =>
            CareerAptitudeTestService.sendCareerTestAnswers({
              answers: this.testAnswers,
              context: context,
            }),
          (data) => {
            this.testResultId = data.data.id;
            this.testResult = new CareerAptitudeTestResult(data.data);
          },
          // TODO: check if handled in the correct way
          (err, reject) => {
            if (err.error.response?.status == 400) {
              this.testIsInvalid = true;
            } else reject(err);
          }
        );
      },
    },
  }
);
