<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6" lg="4" align="center" justify="center">
        <img src="@/assets/illustrations/page-not-found.svg" alt="" />

        <h2 class="primary--text">عذراً، الصفحة المطلوبة غير موجودة</h2>

        <v-btn
          class="accent mt-10 pa-5 rounded-xl"
          @click="$router.push('/')"
        >
          عودة إلى الصفحة الرئيسية
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
