<template>
  <div>
    <v-card
      color="lightSecondary"
      width="300"
      min-height="250"
      rounded="xl"
      hover
      :id="'job-'+job.id"
      :to="'/job-opportunities/' + job.id"
    >
      <v-container>
        <v-row>
          <v-col align-self="start" cols="4" class="secondary--text">
            <v-avatar color="white" size="75">
              <v-img
                v-if="job.jobPublisher.logoURL"
                contain
                :src="job.jobPublisher.logoURL"
                class="rounded"
              ></v-img>
              <v-icon v-else x-large color="lightSecondary">mdi-domain</v-icon>
            </v-avatar>

            <small class="d-block mt-2">{{ job.jobPublisher.name }}</small>
            <small class="accent--text">{{ job.publishedAt }}</small>
            <div>
              <v-icon small color="primary">mdi-map-marker</v-icon>
              <small
                v-if="
                  job.workLocationType == 'on_site' ||
                  job.workLocationType == 'hybrid'
                "
              >
                {{ job.workLocation }}
              </small>
              <small v-else>{{ job.trans(job.workLocationType) }}</small>
            </div>
          </v-col>
          <v-col align="start" cols="8">
            <h3 class="mx-5 my-2 secondary--text">{{ job.title }}</h3>
            <v-card rounded="xl">
              <v-card-text class="primary--text">
                <div>
                  <span class="secondary--text"> المجال:</span> {{ job.pluckFieldNames().toString() }}
                </div>
                <div>
                  <span class="secondary--text"> الخبرة:</span>
                  {{ job.requiredExperienceLevel }}
                </div>
                <div>
                  <span class="secondary--text"> نوع الدوام:</span>
                  {{ job.trans(job.shiftType) }}
                </div>
                <div v-if="job.minSalary && job.maxSalary">
                  <span class="secondary--text"> الراتب:</span>
                  {{ formatCurrency(job.minSalary) }} -
                  {{ formatCurrency(job.maxSalary) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import CurrencyFormattingMixin from "@/core/mixins/CurrencyFormattingMixin.vue";

export default {
  props: {
    job: Object,
  },
  mixins: [CurrencyFormattingMixin],
};
</script>

<style scoped>
.v-card__text, .v-card__title ,.v-card{
  word-break: normal; /* maybe !important  */
}
</style>
