<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      تصفية فرص العمل <v-icon>mdi-filter</v-icon></template
    >
    <template v-slot:body>
      <!--SHIFT TYPES-->
      <v-select
        v-model="shiftTypeFilter"
        rounded
        dense
        chips
        clearable
        item-value="value"
        :items="shiftTypes"
        label="نوع الدوام"
        outlined
      >
        <template v-slot:item="{ item }">
          {{ item.label }}
        </template>
        <template v-slot:selection="{ item }">
          <v-chip>{{ item.label }}</v-chip>
        </template>
      </v-select>
      <!--EXPERIENCE LEVELS-->
      <v-select
        v-model="experienceLevelFilter"
        rounded
        dense
        chips
        clearable
        item-value="value"
        :items="experienceLevels"
        label="مستوى الخبرة "
        outlined
      >
        <template v-slot:item="{ item }">
          {{ item.label }}
        </template>
        <template v-slot:selection="{ item }">
          <v-chip>{{ item.label }}</v-chip>
        </template>
      </v-select>
      <!--JOB LOCATION TYPES-->
      <v-select
        v-model="jobLocationTypeFilter"
        rounded
        dense
        chips
        clearable
        item-value="value"
        :items="jobLocationTypes"
        label="نوع موقع العمل"
        outlined
      >
        <template v-slot:item="{ item }">
          {{ item.label }}
        </template>
        <template v-slot:selection="{ item }">
          <v-chip>{{ item.label }}</v-chip>
        </template>
      </v-select>

      <!--FIELD-->
      <v-select
        v-model="fieldFilter"
        rounded
        dense
        chips
        clearable
        item-value="id"
        :items="jobFields"
        label="مجال فرصة العمل"
        outlined
      >
        <template v-slot:item="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:selection="{ item }">
          <v-chip>{{ item.name }}</v-chip>
        </template>
      </v-select>

      <!--SALARY -->
      <v-slider
        class="mt-7"
        v-model="minSalaryFilter"
        thumb-label="always"
        :thumb-size="42"
        :min="0"
        :max="10000000"
        ticks
        step="200000"
        label="الحد الأدنى للراتب"
      ></v-slider>
    </template>
    <template v-slot:actions>
      <v-btn outlined text rounded @click="resetFilters()"
        >إزالة عوامل التصفية</v-btn
      >
      <v-btn color="accent" rounded @click="setFilterState()"
        >تصفية النتائج </v-btn
      >
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useJobOpportunitiesStore } from "../store/JobOpportunitiesStore";
import {
  shiftTypes,
  experienceLevels,
  jobLocationTypes
} from "./statics/FilterOptions";
export default {
  props: {
    showCondition: Boolean
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      }
    }
  },
  data() {
    return {
      show: this.showCondition,
      shiftTypes: shiftTypes,
      experienceLevels: experienceLevels,
      jobLocationTypes: jobLocationTypes,
      experienceLevelFilter: null,
      jobLocationTypeFilter: null,
      shiftTypeFilter: null,
      fieldFilter:null,
      minSalaryFilter: null
    };
  },
  computed: {
    ...mapState(useJobOpportunitiesStore, [
      "jobFields",
      "selectedExperienceLevel",
      "selectedJobLocationType",
      "selectedShiftType",
      "selectedMinSalary",
      "selectedField",
    ])
  },
  mounted() {
    if (this.jobFields.length == 0)
      //incase fields have not been fetched yet
      this.fetchJobFields()
        .then()
        .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useJobOpportunitiesStore, ["setFilters","fetchJobFields"]),
    closeDialog() {
      this.show = false;
      this.mapStoreFilterWithState();
      this.$emit("closed");
    },
    mapStoreFilterWithState() {
      this.shiftTypeFilter = this.selectedShiftType;
      this.experienceLevelFilter = this.selectedExperienceLevel;
      this.jobLocationTypeFilter = this.selectedJobLocationType;
      this.fieldFilter=this.selectedField;
      this.minSalaryFilter = this.selectedMinSalary;
    },
    resetFilters(){
      this.shiftTypeFilter=null;
      this.experienceLevelFilter=null;
      this.jobLocationTypeFilter=null;
      this.fieldFilter=null;
      this.minSalaryFilter=null;
      this.setFilterState()
    },
    setFilterState() {
      this.setFilters(
        this.shiftTypeFilter,
        this.experienceLevelFilter,
        this.jobLocationTypeFilter,
        this.fieldFilter,
        this.minSalaryFilter
      );
      this.closeDialog();
    }
  }
};
</script>
