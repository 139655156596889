<template>
  <v-container>
    <v-row justify="center">
      <v-col align="center">
        <!--first prevalent types-->
        <h2 class="mb-5 secondary--text">
          أنماط الشخصيات المهنية السائدة لديك
        </h2>
        <v-row
          v-for="careerType in testResult.getFirstPrevalentTypes()"
          :key="careerType"
          :id="careerType"
          justify="center"
          class="mt-5"
        >
          <v-col align="start" align-self="center" cols="10" sm="8" md="6">
            <h3 class="mb-5 secondary--text">
              {{ careerTypes[careerType].title }}
            </h3>
            <div>
              {{ careerTypes[careerType].description }}
            </div>
            <div class="primary--text">
              {{ careerTypes[careerType].professions }}
            </div>
          </v-col>
          <v-col cols="0" md="5" lg="3" class="d-none d-md-flex">
            <v-img
              contain
              :src="
                require('@/assets//illustrations/career-types/' +
                  careerTypes[careerType].illustration)
              "
            ></v-img>
          </v-col>
        </v-row>
        <!--second prevalent types-->
        <div class="mt-5" v-if="testResult.getSecondPrevalentTypes().length > 1">
          <h3 class="mb-5 secondary--text">
            كما أنه لديك نسبة عالية من الأنماط التالية
          </h3>
          <v-row
            v-for="careerType in testResult.getSecondPrevalentTypes()"
            :id="careerType"
            :key="careerType"
            justify="center"
            class="mt-5"
          >
            <v-col align="start" align-self="center" cols="10" sm="8" md="6">
              <h3 class="mb-5 secondary--text">
                {{ careerTypes[careerType].title }}
              </h3>
              <div>
                {{ careerTypes[careerType].description }}
              </div>
              <div class="primary--text">
                {{ careerTypes[careerType].professions }}
              </div>
            </v-col>
            <v-col cols="0" md="5" lg="3" class="d-none d-md-flex">
              <v-img
                contain
                :src="
                  require('@/assets//illustrations/career-types/' +
                    careerTypes[careerType].illustration)
                "
              ></v-img>
            </v-col>
          </v-row>
        </div>
        <slot name="actions"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCareerAptitudeTestStore } from "../store/CareerAptitudeTestStore";
import careerTypes from "./statics/CareerTypes";
export default {
  data() {
    return {
      careerTypes: careerTypes,
    };
  },
  computed: {
    ...mapState(useCareerAptitudeTestStore, ["testResult"]),
  },
};
</script>
