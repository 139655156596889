<template>
  <BaseFullScreenDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title> {{ $t("preview") }}</template>
    <template v-slot:fullBody>
      <!--this element won't be displayed it's just for accessing then setting the pages count number-->
      <div class="d-none">
        <vue-pdf-embed
          @loaded="(doc) => setPageCount(doc)"
          :page="1"
          :source="tempResumePDFFileURL"
        />
      </div>
      <div
        class="elevation-15 mb-5"
        v-for="page in Array.from({ length: pageCount }, (_, i) => i + 1)"
        :key="page"
      >
        <vue-pdf-embed :page="page" :source="tempResumePDFFileURL" />
      </div>
    </template>
  </BaseFullScreenDialog>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      show: this.showCondition,
      pageCount: null,
    };
  },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapState(useCreateResumeStore, ["tempResumePDFFileURL"]),
  },
  methods: {
    setPageCount(doc) {
      this.pageCount = doc._pdfInfo.numPages;
    },
    closeDialog() {
      this.show = false;
      this.$emit("closed");
    },
  },
};
</script>
