import { Notify } from "../notify/Notify";

export class BaseError {
  data;//could be validation errors or any meta data of errors
  message ="خطأ غير متوقع، الرجاء التحقق من اتصالك بالانترنت";//the previewed message for user

  initData(error, data) {
    this.error = error;
    this.data = data;
    return this;
  }
  dispatchError() {
    Notify.showMessage("error",this.message);
  }
}
