import BasePaginatedCollection from "../../base/BasePaginatedCollection";
import JobOpportunity from "../JobOpportunity";

export default class PaginatedJobOpportunityCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map(job => {
      this.collection.push(new JobOpportunity(job));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map(job => {
      this.collection.push(new JobOpportunity(job));
    });
  }
}
