<template>
  <v-btn
    @click="changeRegisterDialogState(true,false)"
    color="lightNeutral"
    rounded
    small
    class="ml-2"
    >إنشاء حساب</v-btn
  >
</template>

<script>
import { mapActions } from "pinia";
import { useAuthStore } from "@/modules/auth/store/AuthStore";
export default {
  methods: {
    ...mapActions(useAuthStore, ["changeRegisterDialogState"]),
  },
};
</script>
