<template>
  <BaseDialog
    :ltr="selectedLanguage == 'en'"
    :showCondition="show"
    @closed="closeDialog()"
  >
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <v-form ref="form">
        <BaseTextField
          v-model="project.name"
          :rules="[requiredRule]"
          :label="name"
          :placeholder="projectNamePlaceholder"
        >
        </BaseTextField>

        <v-checkbox
          class="ma-0 pa-0"
          v-model="project.present"
          :label="currentlyWorking"
        ></v-checkbox>

        <DateField
          v-model="project.startDate"
          :rules="[requiredRule]"
          :label="startDate"
          @changed="(date) => updateStartDate(date)"
        />

        <div v-show="!project.present">
          <DateField
            v-model="project.endDate"
            :label="endDate"
            :rules="project.present ? [] : [requiredRule]"
            @changed="(date) => updateEndDate(date)"
          />
        </div>

        <BaseTextField
          v-model="project.link"
          :label="link"
          :placeholder="projectLinkPlaceholder"
        >
        </BaseTextField>

        <BaseTextField
          v-model="project.description"
          :label="description"
          :placeholder="projectDescriptionPlaceholder"
        >
        </BaseTextField>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-btn color="accent" rounded @click="validateThenAddProject()">
        {{ add }}
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import DateField from "../DateField.vue";
import Project from "../../models/Project.js";
import ResumeValidationMixin from "../ResumeValidationMixin.vue";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import { mapWritableState } from "pinia";

export default {
  mixins:[ResumeValidationMixin],
  components: { DateField },
  data() {
    return {
      show: this.showCondition,
      project: new Project(),
    };
  },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume", "selectedLanguage"]),
    title() {
      return this.$t("add_project");
    },
    add() {
      return this.$t("add");
    },
    name() {
      return this.$t("name");
    },
    startDate() {
      return this.$t("start_date");
    },
    endDate() {
      return this.$t("end_date");
    },
    link() {
      return this.$t("link");
    },
    currentlyWorking() {
      return this.$t("currently_working_project");
    },
    description() {
      return this.$t("description");
    },
    projectNamePlaceholder() {
      return this.$t("ph_project_name");
    },
    projectLinkPlaceholder() {
      return this.$t("ph_project_link");
    },
    projectDescriptionPlaceholder() {
      return this.$t("ph_project_description");
    },
  },
  methods: {
    updateStartDate(date) {
      this.project.startDate = date;
    },
    updateEndDate(date) {
      this.project.endDate = date;
    },
    reset() {
      this.project = new Project();
      this.$refs.form.reset();
    },
    closeDialog() {
      this.reset();
      this.show = false;
      this.$emit("closed");
    },
    validateThenAddProject() {
      if (this.$refs.form.validate()) {
        this.resume.projects.push(this.project);
        this.closeDialog();
      }
    },
  },
};
</script>
