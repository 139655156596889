<template>
  <BaseDialog
    :ltr="selectedLanguage == 'en'"
    :showCondition="show"
    @closed="closeDialog()"
  >
    <template v-slot:title>{{ $t("delete_conformation") }}</template>
    <template v-slot:body>
      <v-col align="center">
        <p>{{ $t("delete_conformation_message") }}</p>
      </v-col>
    </template>

    <template v-slot:actions>
      <v-btn color="light" class="mx-3" rounded @click="closeDialog()"> {{ $t("no") }}</v-btn>
      <v-btn color="error" rounded @click="onDeleteOnConfirmed()"> {{ $t("yes") }}</v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/core/base/dialogs/BaseDialog.vue";
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";

export default {
  components: { BaseDialog },
  data() {
    return {
      show: this.showCondition,
    };
  },
  props: {
    showCondition: Boolean,
    index: Number,
    name: String,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume", "selectedLanguage"]),
  },
  methods: {
    onDeleteOnConfirmed() {
      this.resume[this.name].splice(this.index, 1);
      this.closeDialog();
    },
    closeDialog() {
      this.show = false;
      this.$emit("closed");
    },
  },
};
</script>
