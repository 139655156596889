import UserLayout from "@/core/layouts/UserLayout.vue";
import AboutUsPage from "../pages/AboutUsPage";

export default {
  path: "/about-us",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "about_us",
      component: AboutUsPage,
      meta: { title: "حول الجمعية" },
    },
  ],
};
