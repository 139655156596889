<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title> معايير وشروط التسجيل </template>
    <template v-slot:body>
      <template>
        <v-stepper v-model="currentStep" non-linear>
          <v-stepper-header>
            <v-stepper-step
              edit-icon="mdi-check"
              :complete="currentStep > 1"
              step="1"
              editable
            >
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              edit-icon="mdi-check"
              :complete="currentStep > 2"
              step="2"
              editable
            >
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              edit-icon="mdi-check"
              :complete="currentStep > 3"
              step="3"
              editable
            >
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4" editable> </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="mb-12">
                <strong> {{ stepsContent[0].title }}<br /></strong>
                <div v-html="stepsContent[0].htmlContent"></div>
              </div>
              <v-btn color="primary" rounded @click="currentStep = 2">
                متابعة
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div class="mb-12">
                <strong> {{ stepsContent[1].title }}<br /></strong>
                <ul>
                  <li v-if="getSelectedTrainingProgramCondition()">
                    {{ getSelectedTrainingProgramCondition() }}
                  </li>
                  <li v-else>لا يوجد شروط حالية على برنامج التدريب المختار</li>
                </ul>
              </div>
              <v-btn color="primary" rounded @click="currentStep = 3">
                متابعة
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <div class="mb-12">
                <strong> {{ stepsContent[2].title }}<br /></strong>
                <div v-html="stepsContent[2].htmlContent"></div>
              </div>
              <v-btn color="primary" rounded @click="currentStep = 4">
                متابعة
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="4">
              <div class="mb-12">
                <strong> {{ stepsContent[3].title }}<br /></strong>
                <div v-html="stepsContent[3].htmlContent"></div>
              </div>

              <v-btn
                color="primary"
                class="mr-5 mt-5"
                rounded
                @click="emitAgreed()"
              >
                أوفق على هذه الشروط</v-btn
              >

              <v-btn
                outlined
                rounded
                text
                class="mr-5 mt-5"
                @click="closeDialog()"
              >
                عودة</v-btn
              >
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
    </template>
  </BaseDialog>
</template>

<script>
import termsNConditionsStepsContent from "./statics/TermsNConditionsStepsContent";

export default {
  props: {
    trainingPrograms: Array,
    showCondition: Boolean,
    selectedTrainingProgramId: Number,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  data() {
    return {
      show: this.showCondition,
      stepsContent: termsNConditionsStepsContent,
      currentStep: 1,
    };
  },
  methods: {
    closeDialog() {
      this.show = false;
      this.currentStep = 1;
      this.$emit("closed");
    },
    emitAgreed() {
      this.$emit("agreed");
      this.closeDialog();
    },
    getSelectedTrainingProgramCondition() {
      var condition = null;
      this.trainingPrograms.map((tp) => {
        if (tp.id == this.selectedTrainingProgramId) {
          condition = tp.condition;
          return;
        }
      });
      return condition;
    },
  },
};
</script>
