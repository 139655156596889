<template>
  <v-container>
    <v-row justify="center">
      <ProfessionalSkillsList />
      <SoftSkillsList />
      <LanguagesList />
    </v-row>
  </v-container>
</template>

<script>
import ProfessionalSkillsList from "../../lists/ProfessionalSkillsList.vue";
import SoftSkillsList from "../../lists/SoftSkillsList.vue";
import LanguagesList from "../../lists/LanguagesList.vue";

export default {
  components: {
    ProfessionalSkillsList,
    SoftSkillsList,
    LanguagesList,
  },
};
</script>

<style></style>
