<template>
  <div class="d-flex align-center justify-center media">
    <v-img
      v-show="!iframeLoaded"
      class="media"
      lazy-src="@/assets/logos/brown-logo.svg"
      contain
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            color="accent"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <iframe
      v-show="iframeLoaded"
      :src="src"
      @load="onIframeLoad"
      class="media"
      style="border: none; overflow: hidden"
      scrolling="no"
      frameborder="0"
      allowfullscreen="true"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      iframeLoaded: false,
    };
  },
  methods: {
    onIframeLoad() {
      this.iframeLoaded = true;
    },
  },
};
</script>

<style scoped>
.media {
  width: 100%;
  height: 100%;
}
</style>
