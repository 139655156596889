<script>
export default {
  data() {
    return {
      usernameRule: (v) => !v || /^(?!.*\.{2})[a-zA-Z][a-zA-Z0-9-_]*(?:\.[a-zA-Z0-9-_]+)*$/.test(v) && v.length >= 3 && v.length <= 30 || "يجب أن يكون النص بين 3 و30 حرفاً، ويجب أن يبدأ بحرف، ويمكن أن يحتوي أحرف أو أرقام إنجليزية، بالإضافة لنقاط وشرطات",
      requiredRule: (v) => !!v || "هذا الحقل مطلوب",
      codeRule: (v) =>
        !(v && v.length != 6) || "رمز التحقق يجب أن يكون مكوناً من ستة محارف",
      nameRule: (v) =>
        !(v && v.length < 5) || "الاسم يجب أن يتكون من خمسة محارف على الأقل",
      studyFieldRule: (v) =>
        !(v && v.length < 2) || "مجال الدراسة يجب أن يتكون من محرفين على الأقل",
      confirmPasswordRule(password) {
        return (v) => !(v && v != password) || "كلمة السر غير متطابقة";
      },
      passwordRule: (v) =>
        !(v && v.length < 8) ||
        "كلمة السر يجب أن تتكون من ثمانية محارف على الأقل",
      emailRule: (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "الرجاء إدخال بريد الكتروني صالح",
      phoneNumberRule: (v) =>
        !v ||
        /^([0-9]\d*\d{9,})$/.test(v) ||
        "يرجى إدخال رقم هاتف محمول صالح مكون من عشرة أرقام",
    };
  },
};
</script>

<!-- v => (v && v.length <= 100) || this.$i18n.translate('validation.lt.string', {
'attribute': '', 'value': 100 }) -->
