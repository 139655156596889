<template>
  <v-menu
    v-model="showDate"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <BaseTextField
        :rules="rules"
        :label="label"
        :placeholder="placeholder"
        :append-icon="icon"
        clearable
        persistent-hint
        readonly
        v-bind="{...$attrs,...attrs}"
        v-on="on"
        @change="newVal=>emitChanged(newVal)"
      ></BaseTextField>
    </template>
    <v-date-picker
      v-bind="$attrs"
      :type="type"
      no-title
      @input="newVal=>emitChanged(newVal)"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import BaseTextField from "@/core/base/inputs/BaseTextField.vue";

export default {
  data() {
    return {
      showDate: false,
    };
  },
  props: {
    rules: Array,
    label: String,
    placeholder: {
      type: String,
      default: function () {
        return this.label;
      },
    },
    icon: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "month",
    },
  },
  methods: {
    emitChanged(newVal) {
      this.showDate = false;
      this.$emit("changed", newVal);
    },
  },
  components: { BaseTextField },
};
</script>

<style></style>
