import BasePaginatedCollection from "../../base/BasePaginatedCollection";
import FAQ from "../FAQ";

export default class PaginatedFAQCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map(faq => {
      this.collection.push(new FAQ(faq));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map(faq => {
      this.collection.push(new FAQ(faq));
    });
  }
}
