<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      اقترح برنامجاً تدريبي
    </template>
    <template v-slot:body>
      <v-form ref="form">
        <BaseTextField
          v-model="trainingProgramSuggestion.name"
          :rules="[requiredRule]"
          label="الاسم"
          placeholder="الاسم"
        ></BaseTextField>

        <BaseTextField
          v-model="trainingProgramSuggestion.phoneNumber"
          label="رقم التواصل"
          placeholder="رقم التواصل"
          type="number"
          :rules="[requiredRule, phoneNumberRule]"
        ></BaseTextField>
        <BaseTextField
          v-model="trainingProgramSuggestion.studySpecialty"
          :rules="[requiredRule]"
          label="مجال الدراسة"
          placeholder="مجال الدراسة"
        ></BaseTextField>
        <BaseTextField
          v-model="trainingProgramSuggestion.requiredProfession"
          :rules="[requiredRule]"
          label="المهنة المرتبطة بالبرنامج التدريبي"
          placeholder="المهنة المرتبطة بالبرنامج التدريبي"
        ></BaseTextField>
        <BaseTextArea
          rows="4"
          label="وصف البرنامج المقترح"
          placeholder="قم بوصف البرنامج التدريبي الذي ترغب باقتراح إضافته..."
          v-model="trainingProgramSuggestion.programDescription"
          :rules="[requiredRule]"
        ></BaseTextArea>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn color="accent" rounded @click="sendSuggestionData()">
        إرسال الاقتراح</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions, mapWritableState } from "pinia";
import { useTrainingProgramsStore } from "../store/TrainingProgramsStore";

export default {
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  data() {
    return {
      show: this.showCondition,
    };
  },
  computed: {
    ...mapWritableState(useTrainingProgramsStore, [
      "trainingProgramSuggestion",
    ]),
  },
  methods: {
    ...mapActions(useTrainingProgramsStore, ["postTrainingProgramSuggestion"]),
    closeDialog() {
      this.$refs.form.reset();
      this.show = false;
      this.$emit("closed");
    },
    sendSuggestionData() {
      if (this.$refs.form.validate()) {
        this.postTrainingProgramSuggestion()
          .then(() => this.closeDialog())
          .catch((err) => {
            err;
          });
      }
    },
  },
};
</script>
