import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import "@mdi/font/css/materialdesignicons.css";
import ar from 'vuetify/lib/locale/ar';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  rtl: true,
  lang: {
    locales: { ar },
    current: 'ar',
  },
  theme: {
    options: {
        customProperties: true,
      },
    themes: {
        light: {
            primary: '#A87C4F',
            secondary: '#603912',
            lightSecondary:'#DECEBE',
            accent: '#F7941D',
            lightNeutral:'#F0EBE6',
            light:'#FAF8F6',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
        }
    },
  },
});
