import UserLayout from "@/core/layouts/UserLayout.vue";
import JobOpportunitiesPage from "../pages/JobOpportunitiesPage.vue";
import JobOpportunityPage from "../pages/JobOpportunityPage.vue";
import AppliedJobsOpportunities from "../pages/AppliedJobsOpportunities.vue";

export default {
  path: "/job-opportunities",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "job_opportunities",
      component: JobOpportunitiesPage,
      meta: { title: "فرص العمل" },
    },
    {
        path: "/job-opportunities/my-applied-jobs",
        name: "applied_job_opportunities",
        component: AppliedJobsOpportunities,
        meta: { title: "فرص العمل التي تم التقدم عليها" },
      },
    {
      path: "/job-opportunities/:id",
      name: "job_opportunity",
      component: JobOpportunityPage,
      meta: { title: "فرصة عمل" },
    },
  ],
};
