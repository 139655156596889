export default class JobPublisher{
    constructor(data){
        this.name=data?.name?data.name:'شركة معينة';
        this.field=data?.field;
        this.location=data?.location;
        this.logoURL=data?.logo?process.env.VUE_APP_BASE_URL+data.logo:null;
        this.phoneNumber=data?.phone_number;
        this.email=data?.email;
        this.about=data?.about;
        this.isVisible=data?true:false;
    }
}