<template>
  <v-container  class="pa-0 ma-0 mb-2" v-if="resume.volunteerExperiences.length > 0">
    <BaseDivider :title="this.$t('volunteer_experiences')" />
    <div class="pa-0 ma-0 mb-2" v-for="(experience, i) in resume.volunteerExperiences" :key="i">
      <template>
        <h4 class="pa-0 ma-0 mb-1">{{ experience.role }}</h4>
        <p class="organization pa-0 ma-0">
          {{ experience.organization }} | {{ $t("from") }}
          {{ experience.startDate }} {{ $t("to") }}
          {{ experience.present ? $t("present") : experience.endDate }}
        </p>
        <p v-if="experience.description" class="description pa-0 ma-0 mt-1">
          {{ experience.description }}
        </p>
      </template>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import BaseDivider from "./BaseDivider.vue";
export default {
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
  },
  components: { BaseDivider },
};
</script>

<style scoped>
.organization {
  opacity: 0.9;
  display: inline;
}
.description {
  opacity: 0.8;
}
</style>
