import UserLayout from "@/core/layouts/UserLayout.vue";
import ProfilePage from "../pages/ProfilePage.vue";

export default {
  path: "/profile",
  component: UserLayout,
  children: [
    {
      path: "/profile",
      name: "profile",
      component: ProfilePage,
      meta: { title: "الملف الشخصي" },
    },
  ],
};
