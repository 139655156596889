<template>
  <v-rating
    readonly
    :value="value"
    full-icon="mdi-circle"
    empty-icon="mdi-circle-outline"
    length="5"
    color="white"
    background-color="white"
    size="15px"
  ></v-rating>
</template>

<script>
export default {
  props: {
    value: Number,
  },
};
</script>

<style></style>
