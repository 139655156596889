import NotFound from "@/core/components/NotFound.vue";
import UserLayout from "@/core/layouts/UserLayout.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import { useGlobalStore } from "../store/GlobalStore";
import pinia from "@/store";

const globalStore=useGlobalStore(pinia);

Vue.use(VueRouter);

/**
 * @desc this function is pull all routes from modules , the .routes extension is required required in route files
 * */
const routesFiles = require.context("../", true, /\.routes.js$/);
const routesModules = routesFiles.keys().reduce((routesModules, routePath) => {
  const value = routesFiles(routePath);
  routesModules.push(value.default);
  return routesModules;
}, []);

const routes = [
  //modules routes
  ...routesModules,
  //fallback route
  {
    path: "*",
    component: UserLayout,
    children: [
      {
        path: "*",
        name: "404",
        component: NotFound,
        meta: { title: "صفحة غير موجودة" }
      }
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth", 
      };
    }
  },
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

//change the title of the browser tab according to route's meta title
const DEFAULT_TITLE = "Albarakah | البركة";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  Vue.nextTick(() => {
    if (to.meta.title) document.title = to.meta.title + " | البركة";
    else document.title = DEFAULT_TITLE;
  });
});

router.afterEach(() => {
  window.scrollTo(0, 0);
  globalStore.isDrawerVisible=false;
});

export default router;
