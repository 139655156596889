<template>
    <BaseDialog :showCondition=show @closed="closeDialog()">
        <template v-slot:title>
            أرسل رأيك بالمنصة
        </template>
        <template v-slot:body>
            <v-form ref="form">
             <BaseTextArea
                name="input-7-1"
                label="محتوى الرأي"
                placeholder="محتوى الرأي"
                v-model="ratingNote"
                :rules="[requiredRule]"
            ></BaseTextArea>
            <v-rating
                color="warning"
                hover
                length="5"
                size="40"
                v-model="rating"
                class="text-center"
                ></v-rating>
            </v-form>
        </template>
        <template v-slot:actions>
            <v-btn  color="accent" rounded @click="sendRatingData()">إرسال</v-btn>
        </template>
    </BaseDialog>
</template>

<script>
import { mapActions } from "pinia";
import { useHomeStore } from "./../store";

export default {
    props: {
    showCondition: Boolean,
    },
    watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
    data() {
        return {
            rating: null,
            ratingNote: '',
            show: this.showCondition,
        }
    },
    methods:{
    closeDialog() {
      this.$refs.form.reset();
      this.rating=null;
      this.show = false;
      this.$emit("closed");
    },
    ...mapActions(useHomeStore,['sendRating']),
    sendRatingData(){
        if(this.$refs.form.validate()){
            var ratingData={rate:this.rating,content:this.ratingNote}
            this.sendRating(ratingData)
            .then(() => this.closeDialog() )
            .catch(() => {});
        }
    }
    }
}
</script>