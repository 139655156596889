import { Notify } from "../notify/Notify";
import { BaseError } from "../base/BaseError";
import { useAuthStore } from "@/modules/auth/store/AuthStore";
import pinia from "@/store";

const authStore=useAuthStore(pinia);
export class UnAuthenticatedError extends BaseError {
  sessionExpiredMessage = "انتهت صلاحية جلستك، الرجاء تسجيل الدخول مرة أخرى";
  requiresAuthMessage = "الرجاء تسجيل الدخول لإتمام هذا الإجراء";
  dispatchError() {
    if (authStore.isAuthenticated) {
      //case the token lifetime has expired
      Notify.showMessage("warning", this.sessionExpiredMessage);
    } else {//case no token is stored
      Notify.showMessage("error", this.requiresAuthMessage);
    }
    authStore.localLogout();
  }
}
