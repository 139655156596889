<template>
  <v-container>
    <v-row justify="center">
      <ProjectsList />
      <CertificatesList />
    </v-row>
  </v-container>
</template>

<script>
import ProjectsList from "../../lists/ProjectsList.vue";
import CertificatesList from "../../lists/CertificatesList.vue";

export default {
  components: {
    ProjectsList,
    CertificatesList,
  },
};
</script>

<style></style>
