<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title> إرسال ملاحظة عن فرصة العمل </template>
    <template v-slot:body>
      <small class="accent--text"
        >يمكنك تقديم ملاحظة عن فرصة عمل ما فقط في حال تقدمك السابق لها عن طريق
        المنصة</small
      >
      <v-form ref="form">
        <BaseTextArea
          class="mt-5"
          label="محتوى الملاحظة"
          placeholder="اذكر ملاحظاتك هنا"
          outlined
          persistent-placeholder
          v-model="ratingNote"
          :rules="[requiredRule]"
        ></BaseTextArea>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn color="accent" rounded @click="sendJobRatingNotes()"
        >إرسال الملاحظة</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script>
import { useJobOpportunityStore } from "../store/JobOpportunityStore";
import { mapActions } from "pinia";

export default {
  props: {
    showCondition: Boolean,
    jobId: Number,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  data() {
    return {
      ratingNote: "",
      show: this.showCondition,
    };
  },
  methods: {
    ...mapActions(useJobOpportunityStore, ["sendJobFeedback"]),
    closeDialog() {
      this.$refs.form.reset();
      this.show = false;
      this.$emit("closed");
    },
    sendJobRatingNotes() {
      if (this.$refs.form.validate()) {
        var ratingData = { job_feedback: this.ratingNote };
        this.sendJobFeedback(this.jobId, ratingData)
          .then(() => this.closeDialog())
          .catch(() => {});
      }
    },
  },
};
</script>
