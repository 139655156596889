<template>
  <v-container class="pa-0 ma-0 mb-2" v-if="resume.educations.length > 0">
    <BaseDivider :title="this.$t('educations')" />
    <div class="pa-0 ma-0 mb-2" v-for="(education, i) in resume.educations" :key="i">
      <template>
        <h4 class="pa-0 ma-0">
          {{ education.field }}
        </h4>
        <p class="school pa-0 ma-0 mt-1">
          {{ education.school }} | {{ $t("from") }} {{ education.startDate }}
          {{ $t("to") }}
          {{ education.present ? $t("present") : education.endDate }}
        </p>
      </template>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import BaseDivider from "./BaseDivider.vue";
export default {
  computed: {
    ...mapState(useCreateResumeStore, ["resume", "selectedColor"]),
  },
  components: { BaseDivider },
};
</script>

<style scoped>
.school {
  opacity: 0.8;
}
</style>
