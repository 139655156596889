<template>
  <div>
    <TopNav v-if="!isWebView"></TopNav>
    <SideNav v-if="!isWebView"></SideNav>
    <LoginDialog />
    <RegisterDialog />
    <EmailDialog />
    <LogoutDialog />
    <EmailVerificationDialog />
    <PhoneVerificationDialog />
    <DonateFloatingButton v-if="!isWebView"/>
    <router-view class="margin-view" />
    <PageFooter v-if="!isWebView"></PageFooter>
    <LoadingProgress></LoadingProgress>
    <NotificationAlert></NotificationAlert>
  </div>
</template>
<script>
import TopNav from "../components/navs/TopNav.vue";
import PageFooter from "../components/footer/PageFooter";
import SideNav from "../components/navs/SideNav";
import LoadingProgress from "../components/loader/LoadingProgress";
import NotificationAlert from "../components/notifications/NotificationAlert";
import LoginDialog from "@/modules/auth/components/LoginDialog.vue";
import EmailDialog from "@/modules/auth/components/EmailDialog.vue";
import RegisterDialog from "@/modules/auth/components/RegisterDialog.vue";
import EmailVerificationDialog from "@/modules/auth/components/EmailVerificationDialog.vue";
import PhoneVerificationDialog from "@/modules/auth/components/PhoneVerificationDialog.vue";
import LogoutDialog from "@/modules/auth/components/LogoutDialog.vue";
import DonateFloatingButton from "../components/DonateFloatingButton.vue";

export default {
  components: {
    NotificationAlert,
    LoadingProgress,
    SideNav,
    PageFooter,
    TopNav,
    LoginDialog,
    RegisterDialog,
    EmailDialog,
    PhoneVerificationDialog,
    EmailVerificationDialog,
    LogoutDialog,
    DonateFloatingButton,
},
  computed: {
    isWebView() {
      return this.$route.query.webView;
    },
  },
};
</script>

<style scoped>
.margin-view {
  margin-top: 120px;
  min-height: 70vh;
}
</style>
