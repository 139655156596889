import { defineStore } from "pinia";

import NewsService from "../services/NewsService";
import BaseStore from "@/core/classes/base/BaseStore";
import PaginatedNewsCollection from "../models/PaginatedNewsCollection";
import News from "../models/News";

export const useNewsStore = defineStore("newsStore", {
  state: () => ({
    news: new News(),
    paginatedNews: new PaginatedNewsCollection(),
  }),
  getters: {},
  actions: {
    fetchPaginatedNews() {
      return BaseStore.promiseHandler(
        () => NewsService.getPaginatedNews(this.paginatedNews.currentPage),
        (data) => {
          if (this.paginatedNews.collection.length == 0)
            this.paginatedNews = new PaginatedNewsCollection(data);
          else this.paginatedNews.updatePaginationPage(data);
        }
      );
    },
    fetchNextPage() {
      !this.paginatedNews.isLastPage
        ? this.paginatedNews.currentPage++
        : console.warn("no more pages!");
      this.fetchPaginatedNews();
    },
    fetchPreviousPage() {
      this.paginatedNews.currentPage != 1
        ? this.paginatedNews.currentPage--
        : console.warn("no previous pages!");
      this.fetchPaginatedNews();
    },
    fetchNews(id) {
      return BaseStore.promiseHandler(
        () => NewsService.getNewsDetails(id),
        (data) => {
          this.news = new News(data.data);
        }
      );
    },
  },
});
