<template>
  <BaseAuthenticationWrapper>
    <BaseDecoratorWrapper>
      <v-container>
        <h1 class="text-center secondary--text">الملف الشخصي</h1>
        <v-row v-if="profile != null">
          <v-col>
            <!--Name-->
            <ProfileDataItem
              title="الاسم"
              :description="profile.name"
            ></ProfileDataItem>

            <!--Email-->
            <ProfileDataItem
              v-if="profile.email"
              title="البريد الالكتروني"
              :description="profile.email"
            ></ProfileDataItem>

            <!--Username-->
            <ProfileDataItem
              title="الاسم المُميز لحسابك"
              :description="profile.username"
            ></ProfileDataItem>

            <!--ContactNumber-->
            <ProfileDataItem
              title="رقم التواصل"
              :description="profile.phoneNumber"
            ></ProfileDataItem>

            <!--StudyField-->
            <ProfileDataItem
              title="مجال الدراسة"
              :description="profile.studyField"
            ></ProfileDataItem>

            <!--Interested Fields-->
            <ProfileDataItem
              v-if="profile.pluckFieldNames().length"
              title="المجالات التي تهتم بها"
              :description="profile.pluckFieldNames().toString()"
            ></ProfileDataItem>

            <!-- Change password button-->
            <v-btn
              @click="openChangePasswordDialog()"
              class="ma-5 accent rounded-xl"
              >تغيير كلمة السر</v-btn
            >

            <!--Applied jobs button-->
            <v-btn
              class="ma-5"
              to="/job-opportunities/my-applied-jobs"
              color="primary"
              rounded
              >فرص العمل التي تقدمت لها</v-btn
            >

            <!-- Add email address button-->
            <v-btn
              v-if="!profile.email"
              @click="changeEmailDialogState(true, false, true)"
              class="d-flex ma-5 lightNeutral rounded-xl"
              >إضافة البريد الالكتروني</v-btn
            >

            <!-- Confirm email address button-->
            <v-btn
              v-if="profile.emailVerifiedAt == null && profile.email"
              @click="openVerifyEmailDialog()"
              class="d-flex ma-5 lightNeutral rounded-xl"
              >تأكيد البريد الالكتروني</v-btn
            >
          </v-col>
        </v-row>
      </v-container>

      <ChangePasswordDialog
        :showCondition="showChangePasswordDialog"
        @closed="closeChangePasswordDialog()"
      />
    </BaseDecoratorWrapper>
  </BaseAuthenticationWrapper>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useAuthStore } from "@/modules/auth/store/AuthStore";
import { useProfileStore } from "../store/ProfileStore";
import ChangePasswordDialog from "../components/ChangePasswordDialog.vue";
import ProfileDataItem from "../components/ProfileDataItem.vue";

export default {
  data() {
    return {
      showChangePasswordDialog: false,
    };
  },
  components: { ProfileDataItem, ChangePasswordDialog },
  computed: {
    ...mapState(useProfileStore, ["profile"]),
  },
  methods: {
    ...mapActions(useProfileStore, ["getProfile"]),
    ...mapActions(useAuthStore, [
      "changeEmailVerificationDialogState",
      "changeEmailDialogState",
      "resendVerificationCode",
    ]),
    openChangePasswordDialog() {
      this.showChangePasswordDialog = true;
    },
    closeChangePasswordDialog() {
      this.showChangePasswordDialog = false;
    },
    openVerifyEmailDialog() {
      this.changeEmailVerificationDialogState(true, false);
      this.resendVerificationCode();
    },
  },
  mounted() {
    this.getProfile();
  },
};
</script>

<style scoped></style>
