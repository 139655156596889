<template>
  <BaseDialog
    :showCondition="emailDialogState.show"
    :persistentCondition="emailDialogState.persistent"
    @closed="changeEmailDialogState(false)"
  >
    <template v-slot:title>
      <span class="primary--text"> إدخال البريد الالكتروني </span>
    </template>

    <template v-slot:body>
      <v-form ref="form">
        <v-container>
          <v-row justify="center">
            <v-col align="center" cols="9">
              <div v-if="!emailDialogState.profile">
                من أجل الاستفادة من هذه الخدمة، عليك أولاً أن تقوم بإدخال بريدك
                الإلكتروني
              </div>
              <BaseTextField
                class="mt-3"
                label="البريد الالكتروني"
                placeholder="البريد الالكتروني"
                name="البريد الالكتروني"
                type="email"
                v-model="email"
                :rules="[requiredRule, emailRule]"
                @keyup.enter="validateThenSendEmail()"
              >
              </BaseTextField>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-row class="mb-3" justify="center">
        <v-col align="center">
          <v-row justify="space-around">
            <v-btn
              @click="validateThenAddEmail()"
              class="accent rounded-pill px-10 mt-5"
            >
              إرسال البريد الإلكتروني
            </v-btn>
            <v-btn
              v-if="emailDialogState.persistent"
              text
              outlined
              rounded
              @click="cancelAndRedirectToHome()"
              class="primary--text mt-5"
            >
              العودة للصفحة الرئيسية
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/core/base/dialogs/BaseDialog.vue";
import { mapState, mapActions } from "pinia";
import { useAuthStore } from "../store/AuthStore";
export default {
  data() {
    return {
      email: "",
    };
  },
  props: {},
  components: { BaseDialog },
  computed: {
    ...mapState(useAuthStore, ["emailDialogState"]),
    isWebView() {
      return this.$route.query.webView;
    },
  },
  methods: {
    ...mapActions(useAuthStore, ["addEmail", "changeEmailDialogState"]),
    cancelAndRedirectToHome() {
      this.changeEmailDialogState(false);
      this.$router.push("/");
    },
    validateThenAddEmail() {
      if (this.$refs.form.validate()) {
        var data = { email: this.email };
        this.addEmail(data);
      }
    },
  },
};
</script>

<style scoped></style>
