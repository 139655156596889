import BaseService from "@/core/classes/base/BaseService";
import {
  loginAPI,
  registerAPI,
  addEmailAPI,
  verifyEmailAPI,
  resendVerificationCodeAPI,
  verifyPhoneAPI,
  resendPhoneVerificationCodeAPI,
  logoutAPI,
} from "./Auth.api";
import Mapper from "@/core/classes/mapper/Mapper";
import Client from "@/core/classes/client/Client";

export default class AuthService extends BaseService {
  static login(data) {
    return Client.post(loginAPI, Mapper.camelToUnderscore(data)).then((res) =>
      Promise.resolve(res)
    );
  }

  static register(data) {
    return Client.post(registerAPI, Mapper.camelToUnderscore(data)).then(
      (res) => Promise.resolve(res)
    );
  }

  static addEmail(data) {
    return Client.put(addEmailAPI, Mapper.camelToUnderscore(data)).then((res) =>
      Promise.resolve(res)
    );
  }

  static verifyEmail(data) {
    return Client.post(verifyEmailAPI, Mapper.camelToUnderscore(data)).then(
      (res) => Promise.resolve(res)
    );
  }

  static verifyPhone(data) {
    return Client.post(verifyPhoneAPI, Mapper.camelToUnderscore(data)).then(
      (res) => Promise.resolve(res)
    );
  }

  static resendVerificationCode() {
    return Client.post(resendVerificationCodeAPI).then((res) =>
      Promise.resolve(res)
    );
  }

  static resendPhoneVerificationCode() {
    return Client.post(resendPhoneVerificationCodeAPI).then((res) =>
      Promise.resolve(res)
    );
  }

  static logout() {
    return Client.delete(logoutAPI).then((res) => Promise.resolve(res));
  }
}
