<template>
  <v-container class="my-10">
    <BaseTransitionWrapper>
      <h1 class="secondary--text text-center">خدماتنا</h1>
    </BaseTransitionWrapper>
    <OurServicesItem
      v-for="(service, index) in ourServices"
      :key="index"
      :index="index"
      :imgName="service.imgName"
      :title="service.title"
      :description="service.description"
    />
  </v-container>
</template>

<script>
import OurServicesItem from "./OurServicesItem.vue";

export default {
  components: { OurServicesItem },
  data() {
    return {
      ourServices: [
        {
          title: "التدريب المهني",
          description:
          `على عدد من المهن،  بهدف تمكين الشباب  من عمر  18-35  للدخول إلى سوق العمل  و الاكتفاء مادياً بحيث يركز التدريب على ناحيتين:إتقان المهنة: بالاعتماد على مدربين أكفاء يعملون في المجال الذي يدربون عليه <br>  تعزيز أخلاقيات المهنة: من خلال دورات أخلاقيات العمل المعدة بشكل تخصصي يتناسب مع المهنة`,
          imgName: "training_programs.svg",
        },
        {
          title: "التدريب على أخلاقيات العمل",
          description:
            "افتتاح دورات متخصصة بالتدريب على أخلاقيات العمل من أجل الوصول إلى سوق عمل يحكم المعايير الأخلاقية",
          imgName: "ethics.svg",
        },
        {
          title: "حملة البركة لإغاثة الأسر المتضررة",
          description:
            "إغاثة الأسر المتضررة المقيمة ضمن قطاع الجمعية (منطقتي مساكن برزة وركن الدين) التي تحقق المعايير المعلن عنها في الجمعية",
          imgName: "donation.svg",
        },
        {
          title: "براعم البركة",
          description:
            "مركز متخصص في رعاية الأيتام",
          imgName: "kids.svg",
        },
      ],
    };
  },
};
</script>

<style></style>
