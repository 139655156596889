<template>
  <BaseDecoratorWrapper>
    <TestResultTemplate />
    <BaseAuthenticationWrapper>
      <BaseCareerTest>
        <!--Previous result page-->
        <template v-slot:PreviousResult>
          <v-container>
            <v-col align="center" justify="center">
              <h2 class="mb-5 secondary--text text-center">
                نموذج البركة المطور لمقياس هولاند لتحديد الميول والقدرات المهنية
              </h2>
              <v-row justify="center" class="mt-5">
                <v-col
                  align="center"
                  align-self="center"
                  cols="10"
                  sm="8"
                  md="5"
                >
                  <div class="mb-5">
                    قمت بالفعل بإجراء اختبار سابق، يمكنك الاطلاع على نتيجتك
                    السابقة أو القيام بتصديرها.
                  </div>
                  <v-btn
                    id="export-result-btn"
                    color="accent"
                    class="mr-3 mb-5"
                    rounded
                    dark
                    @click="exportResult()"
                  >
                    تصدير النتيجة السابقة
                    <v-icon class="mr-3"> mdi-file-move-outline</v-icon>
                  </v-btn>
                  <v-btn
                    id="show-result-btn"
                    @click="openResultDialog()"
                    color="lightNeutral"
                    rounded
                    class="mr-3 mb-5 px-7"
                    >عرض النتيجة</v-btn
                  >
                </v-col>
                <v-col cols="0" md="3" class="d-none d-md-flex">
                  <v-img
                    src="@/assets/illustrations/training_programs/career_aptitude_test.png"
                  ></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </template>
        <!--new result page actions-->
        <template v-slot:resultActions>
          <div class="my-10">
            <v-btn
              id="export-result-btn"
              color="accent"
              class="mr-3 mb-5"
              rounded
              dark
              @click="exportResult()"
            >
              تصدير النتيجة
              <v-icon class="mr-3"> mdi-file-move-outline</v-icon>
            </v-btn>
            <v-btn
              id="show-result-btn"
              @click="openResultDialog()"
              color="lightNeutral"
              rounded
              class="mr-5 mb-5 px-5"
              >عرض النتيجة كاملةً</v-btn
            >
          </div>
        </template>
      </BaseCareerTest>
      <!--full result dialog-->
      <FullResultDialog
        :showCondition="showFullResultDialog"
        @closed="closeResultDialog()"
      />
    </BaseAuthenticationWrapper>
  </BaseDecoratorWrapper>
</template>

<script>
import { mapState } from "pinia";
import { useCareerAptitudeTestStore } from "../store/CareerAptitudeTestStore";

import html2pdf from "html2pdf.js";
import { useGlobalStore } from "@/store/GlobalStore";

import BaseCareerTest from "../components/BaseCareerTest.vue";
import TestResultTemplate from "../components/TestResultTemplate.vue";
import FullResultDialog from "../components/FullResultDialog.vue";

const careerAptitudeTestStore = useCareerAptitudeTestStore();
const globalStore = useGlobalStore();

// TODO: create a mixin for exporting, or move the current mixin to global mixins folder to use it inside resume and career test

export default {
  components: { BaseCareerTest, FullResultDialog, TestResultTemplate },
  computed: {
    ...mapState(useCareerAptitudeTestStore, ["testIsInvalid", "testResult"]),
  },
  data() {
    return {
      showFullResultDialog: false,
    };
  },
  methods: {
    exportResult() {
      globalStore.changeLoadingStatus(true);
      const element = document.getElementById("result-template");
      var clonedElement = element.cloneNode(true);
      clonedElement.style.display = "block";
      html2pdf()
        .set({
          filename: "نتيجة مقياس الميول المهني" + ".pdf",
          image: { type: "png", quality: 0.4 },
          html2canvas: { scale: 2, letterRendering: true },
          jsPDF: { unit: "pt", orientation: "portrait", compress: true },
          pagebreak: { mode: ["avoid-all"] },
        })
        .from(clonedElement)
        .save()
        .then(() => {
          globalStore.changeLoadingStatus(false);
        });
    },

    openResultDialog() {
      this.showFullResultDialog = true;
    },
    closeResultDialog() {
      this.showFullResultDialog = false;
    },
  },
  destroyed() {
    careerAptitudeTestStore.$reset();
  },
};
</script>
