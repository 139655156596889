<template>
  <v-btn @click="pushProfilePage()" color="lightNeutral" rounded class="ml-2">
    الملف الشخصي</v-btn
  >
</template>

<script>
export default {
  methods: {
    pushProfilePage() {
      this.$router.push({ name: "profile" });
    }
  }
};
</script>