<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title> كن جزءاً من فريقنا </template>
    <template v-slot:body>
      <v-form ref="form">
        <BaseTextField
          v-model="trainingField"
          :rules="[requiredRule]"
          prepend-icon="mdi-human-male-board-poll"
          label="مجال التدريب"
          placeholder="مثال: تطوير الويب"
        ></BaseTextField>

        <v-file-input
          class="mt-5"
          v-model="resumeFile"
          show-size
          label=" السيرة الذاتية"
          placeholder="أرفق سيرتك الذاتية"
          outlined
          persistent-placeholder
          prepend-icon="mdi-file-account-outline"
          accept="image/jpeg,image/gif,image/png,application/pdf,.docx"
          :rules="[requiredRule]"
        >
        </v-file-input>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn color="accent" rounded @click="sendResumeFile()">
        إرسال الطلب</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions } from "pinia";
import { useJobOpportunitiesStore } from "../store/JobOpportunitiesStore";

export default {
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  data() {
    return {
      show: this.showCondition,
      resumeFile: null,
      trainingField: "",
    };
  },

  methods: {
    ...mapActions(useJobOpportunitiesStore, ["postTrainerResume"]),
    closeDialog() {
      this.$refs.form.reset();
      this.show = false;
      this.$emit("closed");
    },
    sendResumeFile() {
      if (this.$refs.form.validate()) {
        var data = {
          resume: this.resumeFile,
          training_field: this.trainingField,
        };
        this.postTrainerResume(data)
          .then(() => this.closeDialog())
          .catch((err) => {
            err;
          });
      }
    },
  },
};
</script>
