<template>
  <v-app>
    <v-container :class="{ ltr: selectedLanguage == 'en' }" fluid>
      <v-row class="a4-width">
        <!-- Edge -->
        <v-col id="edge" :style="edgeStyle" cols="4">
          <ProfilePicture />
          <ContactInfo />
          <SoftSkills />
          <ProSkills />
          <TheLanguages />
          <TheReferences />
        </v-col>
        <!-- Paper -->
        <v-col class="px-5" cols="8" :style="paperStyle">
          <NameAndAbout />
          <TheEducation />
          <TheCertifications />
          <WorkExperiences />
          <VolunteerExperiences />
          <TheProjects />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";

import VolunteerExperiences from "./components/VolunteerExperiences.vue";
import TheCertifications from "./components/TheCertifications.vue";
import WorkExperiences from "./components/WorkExperiences.vue";
import ProfilePicture from "./components/ProfilePicture.vue";
import TheReferences from "./components/TheReferences.vue";
import TheEducation from "./components/TheEducation.vue";
import NameAndAbout from "./components/NameAndAbout.vue";
import TheLanguages from "./components/TheLanguages.vue";
import TheProjects from "./components/TheProjects.vue";
import ContactInfo from "./components/ContactInfo.vue";
import SoftSkills from "./components/SoftSkills.vue";
import ProSkills from "./components/ProSkills.vue";

export default {
  components: {
    ProfilePicture,
    NameAndAbout,
    ContactInfo,
    SoftSkills,
    ProSkills,
    TheLanguages,
    TheCertifications,
    WorkExperiences,
    VolunteerExperiences,
    TheProjects,
    TheEducation,
    TheReferences,
  },
  computed: {
    ...mapState(useCreateResumeStore, [
      "resume",
      "selectedLanguage",
      "selectedColor",
    ]),
    edgeStyle() {
      return "background-color: " + this.selectedColor + "; color: white;";
    },
    paperStyle() {
      return "background-color: white; color: " + this.selectedColor;
    },
  },
};
</script>

<style scoped>
.a4-width{
  width: 210mm ;
}
.ltr {
  direction: ltr;
}
</style>
