<template>
  <v-container  v-if="resume.certificates.length > 0" class="py-2">
    <BaseDivider :title="this.$t('certifications')" />
    <div class="mb-1" v-for="(certificate, i) in resume.certificates" :key="i">
      <template>
        <h4>
          {{ certificate.name }} |
          <span class="issuer">
            {{ certificate.issuer }}
          </span>
          ( <span>{{ certificate.issueDate }}</span> )
          <v-btn 
          text
          v-if="certificate.link"
          :href="certificate.link"
          target="_blank"
          class="mx-2"
        >
        <v-icon color="gray">mdi-link-variant</v-icon>
       </v-btn>
        </h4>
        <p v-if="certificate.description" class="description">
          {{ certificate.description }}
        </p>
      </template>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import BaseDivider from "./BaseDivider.vue";
export default {
    computed: {
        ...mapState(useCreateResumeStore, ["resume", "selectedColor"]),
        linkStyle() {
            return "color: " + this.selectedColor;
        },
        certificateLink() {
            return this.$t("ph_certificate_link");
        },
    },
    components: { BaseDivider }
};
</script>

<style scoped>
.issuer {
  opacity: 0.8;
}
.description {
  opacity: 0.7;
  display: inline;
}
link {
  color: white;
}
</style>
