import PaginatedJobOpportunityCollection from "@/core/classes/models/paginated_collections/PaginatedJobOpportunityCollection";
import Field from "@/core/classes/models/Field";
import { defineStore } from "pinia";
import JobOpportunitiesService from "../services/JobOpportunitiesService";
import BaseStore from "@/core/classes/base/BaseStore";

export const useJobOpportunitiesStore = defineStore("jobOpportunitiesStore", {
  state: () => ({
    paginatedJobs: new PaginatedJobOpportunityCollection(),
    paginatedJobSuggestions: new PaginatedJobOpportunityCollection(),
    jobFields: [],
    searchKey: null,
    selectedShiftType: null,
    selectedExperienceLevel: null,
    selectedJobLocationType: null,
    selectedField: null,
    selectedMinSalary: null,
  }),
  getters: {
    getFilterSearchPageParameters() {
      return new Object({
        page: this.paginatedJobs.currentPage,
        search_key: this.searchKey,
        shift_type: this.selectedShiftType,
        required_experience: this.selectedExperienceLevel,
        required_field: this.selectedField,
        work_location_type: this.selectedJobLocationType,
        minimum_salary: this.selectedMinSalary,
      });
    },
    filtersAreApplied() {
      return (
        this.selectedExperienceLevel ||
        this.selectedShiftType ||
        this.selectedJobLocationType ||
        this.selectedMinSalary
      );
    },
  },
  actions: {
    resetFilters() {
      this.selectedShiftType = null;
      this.selectedExperienceLevel = null;
      this.selectedJobLocationType = null;
      this.selectedField = null;
      this.selectedMinSalary = null;
      this.resetPaginatedJobs();
      this.fetchJobs();
      this.fetchJobSuggestions();
    },
    setFilters(
      shiftFilter,
      experienceFilter,
      locationTypeFilter,
      fieldFilter,
      minSalaryFilter
    ) {
      this.selectedShiftType = shiftFilter;
      this.selectedExperienceLevel = experienceFilter;
      this.selectedJobLocationType = locationTypeFilter;
      this.selectedField = fieldFilter;
      this.selectedMinSalary = minSalaryFilter;
      this.resetPaginatedJobs();
      this.fetchJobs();
      this.fetchJobSuggestions();
    },
    sendSearchFetch() {
      this.resetPaginatedJobs();
      this.fetchJobs();
      this.fetchJobSuggestions();
    },
    resetPaginatedJobs() {
      this.paginatedJobs = new PaginatedJobOpportunityCollection();
      this.paginatedJobSuggestions = new PaginatedJobOpportunityCollection();
    },
    fetchJobs() {
      return new Promise((resolve, reject) => {
        JobOpportunitiesService.getPaginatedJobOpportunities(
          this.getFilterSearchPageParameters
        )
          .then((res) => {
            let { data, status } = res;
            return JobOpportunitiesService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              this.paginatedJobs = new PaginatedJobOpportunityCollection(data);
              return resolve(res);
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchJobSuggestions() {
      return new Promise((resolve, reject) => {
        JobOpportunitiesService.getPaginatedJobSuggestions(
          this.getFilterSearchPageParameters
        )
          .then((res) => {
            let { data, status } = res;
            return JobOpportunitiesService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              this.paginatedJobSuggestions =
                new PaginatedJobOpportunityCollection(data);
              return resolve(res);
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchJobFields() {
      return new Promise((resolve, reject) => {
        JobOpportunitiesService.getJobFields()
          .then((res) => {
            let { data, status } = res;
            return JobOpportunitiesService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              this.jobFields = [];
              data.data.map((field) => {
                this.jobFields.push(new Field(field));
              });
              return resolve(res);
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchNextPage() {
      !this.paginatedJobs.isLastPage
        ? this.paginatedJobs.currentPage++
        : console.warn("no more pages!");
      this.fetchJobs();
    },
    fetchPreviousPage() {
      this.paginatedJobs.currentPage != 1
        ? this.paginatedJobs.currentPage--
        : console.warn("no previous pages!");
      this.fetchJobs();
    },

    fetchSuggestionsNextPage() {
      !this.paginatedJobSuggestions.isLastPage
        ? this.paginatedJobSuggestions.currentPage++
        : console.warn("no more pages!");
      this.fetchJobSuggestions();
    },
    fetchSuggestionsPreviousPage() {
      this.paginatedJobSuggestions.currentPage != 1
        ? this.paginatedJobSuggestions.currentPage--
        : console.warn("no previous pages!");
      this.fetchJobSuggestions();
    },
    postTrainerResume(data) {
      return BaseStore.promiseHandler(() =>
      JobOpportunitiesService.sendTrainerResume(data)
      );
    },
  },
});
