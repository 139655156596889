<template>
  <v-container v-if="resume.languages.length > 0" class="py-2">
    <BaseDivider :title="this.$t('languages')" />
    <div v-for="(language, i) in resume.languages" :key="i">
      <v-container>
        <v-row>
          <v-col cols="4">
            <b >{{ language.name }}</b>
          </v-col>
          <v-col cols="6" align="start">
            <ProficiencyPercentage :value="language.proficiency" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>
<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";

import ProficiencyPercentage from "./ProficiencyPercentage.vue";
import BaseDivider from "./BaseDivider.vue";

export default {
  components: { ProficiencyPercentage, BaseDivider },
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
  },
};
</script>

<style></style>
