<template>
  <v-container v-if="resume.languages.length > 0" class="pt-0 pb-0 mb-3">
    <BaseDivider :title="this.$t('languages')" />
    <div v-for="(language, i) in resume.languages" :key="i">
      <template>
        <span class="mx-2">{{ language.name }}</span>
        <ProficiencyRate :value="language.proficiency" />
      </template>
    </div>
  </v-container>
</template>
<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";

import ProficiencyRate from "./ProficiencyRate.vue";
import BaseDivider from "./BaseDivider.vue";

export default {
  components: { ProficiencyRate, BaseDivider },
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
  },
};
</script>

<style></style>
