import BaseService from "@/core/classes/base/BaseService";
import { getCreatedResumeAPI, postCreatedResumeAPI } from "./CreateResume.api";
import Client from "@/core/classes/client/Client";

export default class CreateResumeService extends BaseService {
  static postCreatedResume(resumeData, selectedLanguage) {
    return Client.post(postCreatedResumeAPI, {
      resume: resumeData,
      selectedLanguage: selectedLanguage,
    }).then((res) => Promise.resolve(res));
  }
  static getCreatedResume() {
    return Client.get(getCreatedResumeAPI).then((res) => Promise.resolve(res));
  }
}
