<template>
  <v-container  class="pa-0 ma-0 mb-2" v-if="resume.workExperiences.length > 0">
    <BaseDivider :title="this.$t('work_experiences')" />
    <div class="pa-0 ma-0 mb-2" v-for="(experience, i) in resume.workExperiences" :key="i">
      <template>
        <h4 class="pa-0 ma-0">{{ experience.title }} | {{ experience.company }}</h4>
        <p class="location pa-0 ma-0">
          {{ experience.location }} |  {{ $t("from") }} {{ experience.startDate }} {{ $t("to") }}
          {{ experience.present ? $t("present"): experience.endDate }}
        </p>
        <p v-if="experience.description" class="description  pa-0 ma-0 mt-1">
          {{ experience.description }}
        </p>
      </template>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import BaseDivider from "./BaseDivider.vue";
export default {
    computed: {
        ...mapState(useCreateResumeStore, ["resume"]),
    },
    components: { BaseDivider }
};
</script>

<style scoped>
.location {
  opacity: 0.9;
  display: inline;
}
.description {
  opacity: 0.8;
}
</style>
