import Client from "@/core/classes/client/Client";
import BaseService from "@/core/classes/base/BaseService";

import { getNewsAPI, getNewsDetailsAPI } from "./News.api";

export default class HomeService extends BaseService {
  static getPaginatedNews(pageNumber = 1) {
    return Client.get(getNewsAPI, {
      page: pageNumber,
    }).then((res) => Promise.resolve(res));
  }
  static getNewsDetails(id) {
    return Client.get(getNewsDetailsAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
}
