import BaseService from "@/core/classes/base/BaseService";
import {
  getResultAPI, getTestQuestionsAPI, sendCareerTestAnswersAPI
} from "./CareerAptitudeTest.api";
import Mapper from "@/core/classes/mapper/Mapper";
import Client from "@/core/classes/client/Client";

export default class CareerAptitudeTestService extends BaseService {
  static getCareerTestResult(context) {
    return Client.get(getResultAPI, { context: context }).then(res => Promise.resolve(res));
  }
  static getTestQuestions() {
    return Client.get(getTestQuestionsAPI).then(res => Promise.resolve(res));
  }
  static sendCareerTestAnswers(data) {
    return Client.post(sendCareerTestAnswersAPI, Mapper.camelToUnderscore(data)).then(
      res => Promise.resolve(res)
    );
  }

}
