import BaseService from "@/core/classes/base/BaseService";
import {
  getTPsAPI,
  sendTPSuggestionAPI,
  getTrainingProgramAPI,
  getTrainingProgramQuestionsAPI,
  sendTrainingProgramApplicationAPI,
  getTPSuggestingStatusAPI,
} from "./TrainingProgramApplication.api";
import Mapper from "@/core/classes/mapper/Mapper";
import Client from "@/core/classes/client/Client";

export default class TrainingProgramApplicationService extends BaseService {
  static getAllTrainingPrograms() {
    return Client.get(getTPsAPI).then((res) => Promise.resolve(res));
  }
  static sendTrainingProgramSuggestion(data) {
    return Client.post(
      sendTPSuggestionAPI,
      Mapper.camelToUnderscore(data)
    ).then((res) => Promise.resolve(res));
  }
  static getTPSuggestingStatus() {
    return Client.get(getTPSuggestingStatusAPI).then((res) =>
      Promise.resolve(res)
    );
  }
  static getTrainingProgram(id) {
    return Client.get(getTrainingProgramAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
  static getTrainingProgramQuestions() {
    return Client.get(getTrainingProgramQuestionsAPI).then((res) =>
      Promise.resolve(res)
    );
  }
  static sendTrainingProgramApplication(id, data) {
    return Client.post(sendTrainingProgramApplicationAPI(id), data).then(
      (res) => Promise.resolve(res)
    );
  }

}
