<template>
  <div id="deletable-card">
    <v-card class="hover light my-5 rounded-xl py-4" style="position: relative">
      <v-card-text class="pt-2 pb-0">
        <slot></slot>
      </v-card-text>
      <v-btn small icon class="delete-btn rounded-lg" @click="openDialog()">
        <v-icon color="error">mdi-delete</v-icon>
      </v-btn>
    </v-card>
    <DeleteConfirmationDialog
      :index="index"
      :name="name"
      :showCondition="showDialog"
      @closed="closeDialog()"
    />
  </div>
</template>

<script>
import DeleteConfirmationDialog from "./dialogs/DeleteConfirmationDialog.vue";
export default {
  data() {
    return {
      showDialog: false,
    };
  },
  components: { DeleteConfirmationDialog },
  props: {
    index: Number,
    name: String,
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style scoped>
.delete-btn {
  position: absolute;
  top: 10px;
  left: 10px;
}
.hover:hover {
  cursor: grab;
}
.hover:active {
  cursor: grabbing;
}
</style>
