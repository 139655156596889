export default class Media {
  id;
  mediableId;
  type;
  externalPlatform;
  externalLink;
  localPath;
  isCover;
  constructor(data) {
    this.id = data?.id;
    this.type = data?.type;
    this.isCover = data?.is_cover;
    this.localPath = data?.local_path
      ? process.env.VUE_APP_BASE_URL + data?.local_path
      : undefined;
    this.mediableId = data?.mediable_id;
    this.externalLink = data?.external_link;
    this.externalPlatform = data?.external_platform;
  }
}
