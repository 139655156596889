<template>
  <BaseTransitionWrapper>
    <!-- if index is even display description then image -->
    <v-row
      v-if="index % 2 == 0"
      class="my-10"
      align="center"
      justify="space-around"
    >
      <v-col cols="12" md="5" align="center">
        <v-sheet
          class="rounded-circle accent text-center light--text py-4"
          height="55px"
          width="55px"
        >
          <p>{{ this.index + 1 }}</p>
        </v-sheet>
        <h1 class="my-4 primary--text">{{ this.title }}</h1>
        <p v-html="description"></p>
      </v-col>

      <v-col class="d-none d-md-inline" cols="5" align="center">
        <v-img
          contain
          :src="require('@/assets/illustrations/about_us/' + imgName)"
        ></v-img>
      </v-col>
    </v-row>

    <!-- else display image then description -->
    <v-row v-else class="my-10" align="center" justify="space-around">
      <v-col class="d-none d-md-inline" cols="5" align="center">
        <v-img
          contain
          :src="require('@/assets/illustrations/about_us/' + imgName )"
        ></v-img>
      </v-col>

      <v-col cols="12" md="5" align="center">
        <v-sheet
          class="rounded-circle accent text-center light--text py-4"
          height="55px"
          width="55px"
        >
          <p>{{ this.index + 1 }}</p>
        </v-sheet>
        <h1 class="my-4 primary--text">{{ this.title }}</h1>
        <p v-html="description"></p>
      </v-col>
    </v-row>
  </BaseTransitionWrapper>
</template>

<script>
export default {
  props: {
    index: Number,
    imgName: String,
    title: String,
    description: String,
  },
};
</script>

<style></style>
