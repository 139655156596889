<template>
  <v-container>
    <v-row class="px-5" justify="center">
      <v-col cols="12" md="8" align="center" justify="center">
        <v-form ref="form">
          <!-- Name -->
          <TitledComponent :title="this.$t('full_name')">
            <v-text-field
              :rules="[requiredRule]"
              v-model="resume.name"
              outlined
              :placeholder="this.$t('ph_full_name')"
            ></v-text-field>
          </TitledComponent>

          <!-- Date of birth -->
          <TitledComponent :title="this.$t('date_of_birth')">
            <DateField
              type="date"
              v-model="resume.dateOfBirth"
              icon="mdi-calendar-range"
              :placeholder="this.$t('ph_date_of_birth')"
              @changed="(date) => updateDate(date)"
            />
          </TitledComponent>

          <!-- Picture -->
          <TitledComponent :title="this.$t('profile_picture')">
            <v-file-input
              v-model="resume.profilePicture"
              append-icon="mdi-account-circle"
              accept="image/jpeg"
              :placeholder="this.$t('ph_profile_picture')"
              outlined
              prepend-icon=""
            ></v-file-input>
          </TitledComponent>

          <!-- Gender -->
          <TitledComponent :title="this.$t('gender')">
            <v-row>
              <v-col>
                <v-radio-group
                  class="ma-0 pa-0"
                  row
                  v-model="resume.gender"
                  :rules="[requiredRule]"
                >
                  <v-radio color="accent" value="male">
                    <h3
                      slot="label"
                      class="mx-2"
                      :class="{ 'accent--text': resume.gender == 'male' }"
                    >
                      {{ this.$t("male") }}
                    </h3>
                  </v-radio>
                  <v-spacer></v-spacer>
                  <v-radio color="accent" value="female">
                    <h3
                      slot="label"
                      class="mx-2"
                      :class="{ 'accent--text': resume.gender == 'female' }"
                    >
                      {{ this.$t("female") }}
                    </h3>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </TitledComponent>

          <!-- About -->
          <TitledComponent :title="this.$t('pitch_yourself')">
            <v-textarea
              v-model="resume.about"
              :rules="[requiredRule]"
              outlined
              :placeholder="this.$t('ph_pitch_yourself')"
            ></v-textarea>
          </TitledComponent>

          <!-- Phone Number -->
          <TitledComponent :title="this.$t('phone_number')">
            <v-text-field
              :rules="[requiredRule]"
              v-model="resume.phoneNumber"
              append-icon="mdi-phone"
              type="number"
              outlined
              :placeholder="this.$t('ph_phone_number')"
            ></v-text-field>
          </TitledComponent>

          <!-- Email -->
          <TitledComponent :title="this.$t('email')">
            <v-text-field
              :rules="[requiredRule, emailRule]"
              v-model="resume.email"
              append-icon="mdi-email"
              type="email"
              outlined
              :placeholder="this.$t('ph_email')"
            ></v-text-field>
          </TitledComponent>

          <!-- Residence -->
          <TitledComponent :title="this.$t('address')">
            <v-text-field
              :rules="[requiredRule]"
              v-model="resume.residence"
              append-icon="mdi-map-marker"
              outlined
              :placeholder="this.$t('ph_address')"
            ></v-text-field>
          </TitledComponent>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitledComponent from "../../TitledComponent.vue";
import DateField from "../../DateField.vue";
import ResumeValidationMixin from "../../ResumeValidationMixin.vue";
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../../store/CreateResumeStore";

export default {
  mixins: [ResumeValidationMixin],
  components: { TitledComponent, DateField },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume"]),
  },
  methods: {
    updateDate(date) {
      this.resume.dateOfBirth = date;
    },
  },
};
</script>

<style scoped></style>
