export default[
    {
        title:'الصفحة الرئيسية',
        link:'/',
    },
    {
        title:'الأخبار',
        link:'/news',
    },
    {
        title:' المشاريع المتميزة',
        link:'/projects-fair',
    },
    {
        title:'التقدم للمنحة التدريبية',
        link:'/training-programs',
    },
    {
        title:'اختبار الميول المهني',
        link:'/career-aptitude-test',
    },
    {
        title:'إنشاء سيرة ذاتية',
        link:'/create-resume',
    },
    {
        title:'فرص العمل',
        link:'/job-opportunities',
    },
    {
        title:'الأسئلة الشائعة',
        link:'/faqs',
    },
    {
        title:'من نحن',
        link:'/about-us',
    },
];