<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
import { useAuthStore } from '@/modules/auth/store/AuthStore';
import { mapActions, mapState } from 'pinia';

export default{
    name:"BaseAuthenticationWrapper",
    mounted(){
        if(!this.isAuthenticated)
        {
            this.changeLoginDialogState(true,true);
        }
    },
    computed:{
        ...mapState(useAuthStore,['isAuthenticated'])
    },
    methods:{
        ...mapActions(useAuthStore,['changeLoginDialogState'])
    }
}

</script>