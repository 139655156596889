import { BaseError } from "../base/BaseError";
import pinia from "@/store";
import { useAuthStore } from "@/modules/auth/store/AuthStore";

const authStore = useAuthStore(pinia);

export class NullEmailError extends BaseError {
  message = "يجب عليك إدخال البريد الإلكتروني من أجل الاستفادة من هذه الخدمة";

  dispatchError() {
    authStore.changeEmailDialogState(true, true);
  }
}
