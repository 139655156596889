<template>
    <v-card rounded="xl" raised color="lightSecondary" hover>
        <v-card-title class="card__title" primary-title>
            {{ faq.question }}
            <v-chip class="mr-3" color="primary" dark>{{ faq.tag }}</v-chip>
        </v-card-title>

        <v-card-text class="text-centered">
            {{ faq.answer }}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        faq: Object
    }
}
</script>
<style scoped>
[class*='text'] {
    white-space: normal;
}

.v-card__title {
    word-break: normal;
}
</style>