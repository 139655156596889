export default class CareerAptitudeTestResult {
  R;
  I;
  A;
  S;
  E;
  C;
  constructor(data) {
    this.R = data?.R ? data.R : 0;
    this.I = data?.I ? data.I : 0;
    this.A = data?.A ? data.A : 0;
    this.S = data?.S ? data.S : 0;
    this.E = data?.E ? data.E : 0;
    this.C = data?.C ? data.C : 0;
  }
  /**
   *
   * @returns array of prevalent career types codes with max score
   */
  getFirstPrevalentTypes() {
    var maxScore = 0;
    var prevalentTypes = [];
    Object.keys(this).forEach((type) => {
      if (this[type] > maxScore) maxScore = this[type];
    });
    Object.keys(this).forEach((type) => {
      if (this[type] == maxScore) prevalentTypes.push(type);
    });
    return prevalentTypes;
  }
  /**
   *
   * @returns array of second prevalent career types codes with second max score
   */
  getSecondPrevalentTypes() {
    var scoresSet = new Set();
    var SecondPrevalentTypes = [];
    Object.keys(this).forEach((type) => {
      scoresSet.add(this[type]);
    });
    const sortedScores = [...scoresSet].sort(
      (value1, value2) => value2 - value1
    );
    if (sortedScores.length > 1 && this.getFirstPrevalentTypes().length<2 && sortedScores[1]!=0) {
      //if there are at least two max scores and the first prevalent types are less than two types and the second top score is not 0
      var secondMaxScore = sortedScores[1]; //second max score
      Object.keys(this).forEach((type) => {
        if (this[type] == secondMaxScore) SecondPrevalentTypes.push(type);
      });
    }
    return SecondPrevalentTypes;
  }

  /**
   * this method returns prevalent types codes
   */
  getTopTypesCodes() {
    return [
      ...this.getFirstPrevalentTypes(),
      ...this.getSecondPrevalentTypes(),
    ];
  }

  /*
   * this method will calculate the percentage of a type among other top types
   */
  getTypePercentage(typeName) {
    var totalScore = 0;
    Object.keys(this).forEach((type) => {
      totalScore += this[type];
    });
    var percentage = (100 * this[typeName]) / totalScore;
    return Number(percentage).toFixed(1);
  }
}
