import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import UserLayout from "@/core/layouts/UserLayout.vue";
import CareerTestPage from '../pages/CareerTestPage.vue';

export default {
  path: "/career-aptitude-test",
  component: UserLayout,
  children: [
    {
      path: "",
      component:BaseFormPageWrapper,
      children:[
        {
          path:"",
          name: "career_aptitude_test", 
          component:CareerTestPage ,
          meta: { title: "اختبار الميول المهني" }
        }
      ]
     
    },
  ]
};
