<template>
  <BaseDialog
    :showCondition="phoneVerificationDialogState.show"
    :persistentCondition="phoneVerificationDialogState.persistent"
    @closed="changePhoneVerificationDialogState(false)"
  >
    <template v-slot:title>
      <span class="primary--text"> تأكيد الحساب </span>
    </template>

    <template v-slot:body>
      <v-form ref="form">
        <v-container fluid>
          <v-row no-gutters justify="center">
            <v-col align="center" cols="12" sm="9">
              <p>
                لقد قمنا بإرسال رمز التحقق إلى رقمك عبر الواتساب<br />
                <span class="secondary--text">{{ phoneNumber }}</span
                ><br />
                  يرجى التحقق من الرسالة وإدخال الرمز والرد على رسالة الرمز على الواتساب بأي شكل
              </p>
              <v-otp-input
                class="ltr"
                :rules="[requiredRule, codeRule]"
                v-model="code"
                length="6"
              ></v-otp-input>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-row justify="center">
        <v-col align="center">
          <v-btn @click="verify()" class="accent rounded-pill px-10">
            تأكيد الحساب
          </v-btn>

          <div class="mt-5">
            <v-btn text @click="resendVerificationCode()">
              لم يصلك رمز التأكيد؟
              <span class="accent--text text-decoration-underline">
                إعادة إرسال الرمز
              </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/core/base/dialogs/BaseDialog.vue";
import { mapState, mapActions } from "pinia";
import { useAuthStore } from "../store/AuthStore";
export default {
  data() {
    return {
      code: "",
    };
  },
  components: { BaseDialog },
  computed: {
    ...mapState(useAuthStore, ["phoneVerificationDialogState", "phoneNumber"]),
  },
  methods: {
    ...mapActions(useAuthStore, [
      "changePhoneVerificationDialogState",
      "verifyPhone",
      "resendVerificationCode",
    ]),
    verify() {
      if (this.$refs.form.validate()) {
        var data = { token: this.code };
        this.verifyPhone(data).then(
          ()=>{
            this.$router.go()
          }
        ).catch((err)=>console.err(err));
      }
    },
  },
};
</script>

<style scoped>
.ltr {
  direction: ltr !important;
}
</style>
