<template>
  <div class="loading-area">
    <v-progress-linear
      :active="getIsLoading"
      indeterminate
      absolute
      top
      color="accent"
      height="5px"
      class="mt-0 progress-bar"
    ></v-progress-linear>
   <v-overlay :value="getIsLoading" color="lightNeutral" opacity="0.5">
      <v-progress-circular
        indeterminate
        size="64"
        color="accent"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { useGlobalStore } from '@/store/GlobalStore';
import { mapState } from 'pinia';

export default {
  computed: {
    ...mapState(useGlobalStore,['getIsLoading'])
  }
};
</script>

<style scoped>
.loading-area {
  z-index: 999;
  position: fixed;
}
.progress-bar {
  z-index: 999;
  position: fixed;
}
</style>
