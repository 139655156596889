import { defineStore } from "pinia";

import Resume from "../models/Resume";
import BaseStore from "@/core/classes/base/BaseStore";
import CreateResumeService from "../services/CreateResumeService";

export const useCreateResumeStore = defineStore("CreateResumeStore", {
  state: () => ({
    selectedLanguage: "ar",
    selectedTemplate: 0,
    selectedColor: "#323b4c",
    step: 1,
    resume: new Resume(),
    tempResumePDFFileURL: null,
    showPreviewDialog: false,
  }),
  getters: {
    resumeHasPersonalInfo() {
      return (
        !!this.resume.name &&
        !!this.resume.dateOfBirth &&
        !!this.resume.gender &&
        !!this.resume.about &&
        !!this.resume.phoneNumber &&
        !!this.resume.email &&
        !!this.resume.residence
      );
    },
  },
  actions: {
    changeCurrentLanguage() {
      this.selectedLanguage == "ar"
        ? (this.selectedLanguage = "en")
        : (this.selectedLanguage = "ar");
    },
    fetchUserCreatedResume() {
      return BaseStore.promiseHandler(
        () => CreateResumeService.getCreatedResume(),
        (data) => {
          var resumeCreatedBefore = data.data.resume_created_before;
          if (resumeCreatedBefore) {
            this.resume = new Resume(data.data.resume.content);
            this.selectedLanguage = data.data.resume.language;
          }
        }
      );
    },
    sendUserCreatedResume() {
      return BaseStore.promiseHandler(() =>
        CreateResumeService.postCreatedResume(
          this.resume,
          this.selectedLanguage
        )
      );
    },
  },
});

/*resume: {
    name: "حمزة عمار",
    dateOfBirth: "30-7-1999",
    gender: "male",
    about:
      "مدير تموين متمرس يتمتع بخبرة تزيد عن 5 سنوات في صناعة الضيافة. يتمتع بخلفية قوية في تخطيط الأحداث وتخطيط القائمة وخدمة العملاء. لديه سجل حافل في تقديم خدمة عملاء ممتازة وضمان أحداث سلسة وناجحة. إنه فرد منظم وموجه نحو التفاصيل ولديه مهارات ممتازة في حل المشكلات.",
    phoneNumber: "0953954152",
    email: "hamza.99.ammar@gmail.com",
    residence: "سوريا، دمشق، برزة",
    links: [
      {
        title: "Hamza Ammar",
        icon: "mdi-facebook",
        url: "https://www.facebook.com/hamza.ammar1999",
      },
      {
        title: "Web Test",
        icon: "mdi-web",
        url: "https://www.google.com",
      },
    ],
    educations: [
      {
        school: "جامعة دمشق",
        field: "الهندسة المعلوماتية",
        startDate: "9-2018",
        endDate: "",
        present: true,
      },
    ],
    projects: [
      {
        name: "نظام اتمتة مبيعات معمل",
        description:
          "من المفترض أن يكون هذا وصفاً عن المشروع، من المفترض أن يكون هذا وصفاً عن المشروع، من المفترض أن يكون هذا وصفاً عن المشروع، من المفترض أن يكون هذا وصفاً عن المشروع",
        link: "https://www.google.com",
        startDate: "8-2020",
        endDate: "8-2022",
        present: false,
      },
    ],
    languages: [
      {
        name: "العربية",
        proficiency: 5,
      },
      {
        name: "الانكليزية",
        proficiency: 4,
      },
    ],
    references: [
      {
        name: "أحمد حمادة",
        jobTitle: "مشرف عام",
        description: "زميلي في العمل",
        contactInfo: "hammodah@email.com",
      },
      {
        name: "سالم الدوسري",
        jobTitle: "لاعب كرة قدم",
        description: "مديري",
        contactInfo: "ilovefootaball@email.com",
      },
    ],
    softSkills: [
      {
        title: "القراءة",
      },
      {
        title: "الكتابة",
      },
      {
        title: "المطالعة",
      },
      {
        title: "العمل تحت الضغط",
      },
      {
        title: "الإبداع",
      },
      {
        title: "الروعة",
      },
      {
        title: "الصدق",
      },
      {
        title: "الإخلاص",
      },
    ],
    certificates: [
      {
        issuer: "اسم المنظمة",
        name: "حسن سلوك",
        description:
          "إن إنشاء سيرة ذاتية مثالية لبدء حياتك المهنية ليس بالمهمة السهلة. يمكن أن يساعدك اتباع قواعد الكتابة العامة",
        issueDate: "1-2002",
        link: "https://www.facebook.com",
      },
      {
        issuer: "الجمعية السورية للمعلوماتية",
        name: "شهادة ICDL",
        issueDate: "1-2000",
      },
    ],
    workExperiences: [
      {
        title: "مشرف مبيعات",
        company: "العالمية للاستيراد والتصدير",
        location: "اللاذقية، سوريا",
        present: false,
        startDate: "4-2019",
        endDate: "5-2019",
        description:
          "الاشراف على استيراد وتصدير البضائع، الاشرف على استيراد وتصدير البضائع، الاشراف على استيراد وتصدير البضائع",
      },
      {
        title: "مسؤول مبيعات",
        company: "العربية للاستيراد والتصدير",
        location: "طرطوس، سوريا",
        present: true,
        startDate: "5-2019",
        endDate: "10-2019",
      },
    ],
    professionalSkills: [
      {
        title: "PHP",
        proficiency: 2,
      },
      {
        title: "Flutter",
        proficiency: 5,
      },
      {
        title: "Dart",
        proficiency: 5,
      },
    ],
    volunteerExperiences: [
      {
        role: "منضد",
        organization: "الكريات الحمراء",
        description:
          "كتابة وتنسيق المحاضرات، كتابة وتنسيق المحاضرات، كتابة وتنسيق المحاضرات، كتابة وتنسيق المحاضرات، كتابة وتنسيق المحاضرات، كتابة وتنسيق المحاضرات، كتابة وتنسيق المحاضرات.",
        startDate: "5-2022",
        present: true,
      },
      {
        role: "منظم سير الشاحنات",
        organization: "سوق الهال",
        startDate: "5-2022",
        endDate: "5-2022",
        present: false,
      },
    ],
  },
  */

// resume: {
// name: "حمزة عمار",
// dateOfBirth: "30-7-1999",
// gender: "male",
// about:
//   "مدير تموين متمرس يتمتع بخبرة تزيد عن 5 سنوات في صناعة الضيافة. يتمتع بخلفية قوية في تخطيط الأحداث وتخطيط القائمة وخدمة العملاء. لديه سجل حافل في تقديم خدمة عملاء ممتازة وضمان أحداث سلسة وناجحة. إنه فرد منظم وموجه نحو التفاصيل ولديه مهارات ممتازة في حل المشكلات.",
// phoneNumber: "0953954152",
// email: "hamza.99.ammar@gmail.com",
// residence: "سوريا، دمشق، برزة",
//   links: [],
//   projects: [],
//   languages: [],
//   references: [],
//   softSkills: [],
//   educations: [],
//   certificates: [],
//   workExperiences: [],
//   professionalSkills: [],
//   volunteerExperiences: [],
// },
