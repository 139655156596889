<template>
  <v-container class="my-10">
    <BaseTransitionWrapper type="top">
      <h1 class="secondary--text text-center">التبرع</h1>
      <p class="text-center">بإمكانكم التبرع لدعم خدمات جمعية البركة عبر</p>

      <v-row id="donation" justify="space-around" align="center" class="my-10">
        <v-col cols="12" md="5" align="center" justify="center">
          <h3 class="primary--text">التوجه مباشرة لأحد مراكز الجمعية</h3>
          <br />
          <p>
            <span class="secondary--text"
              >المركز الرئيسي الإداري - مساكن برزة مقابل مشفى ابن النفيس </span
            ><br />هاتف: 5118214 - 5118241 - 0991086666 <br />من الساعة 9:00
            صباحاً إلى 6:00 عصراً
          </p>
          <p>
            <span class="secondary--text"> مركز إدارة التدريب - الحلبوني</span>
            <br />هاتف: 2232378
            <br />
            من الساعة 11:00 صباحاً و حتى 5:00 عصراً
          </p>
          <p>
            <span class="secondary--text"
              >بيت الريادة - الروضة - أول نزلة مطعم اللوتس</span
            ><br />
            هاتف: 3349074 - 0993301243 <br />من الساعة 9:00 صباحاً حتى 6:00
            عصراً
          </p>
          <br />
          <h3 class="primary--text">
            أو عن طريق الحساب البنكي، على حساب: <br>
            جمعية البركة للتنمية الاجتماعية
          </h3>
          <br />
          <p>
            <span class="secondary--text"> بنك البركة الإسلامي </span>
            <br />حساب الزكاة : 0/7000676 <br />حساب الصدقات : 1/7000676
          </p>
          <p  >
            <span class="secondary--text"> البنك الدولي الإسلامي </span>
            <br />حساب الزكاة : 00/ 201434<br />حساب الصدقات: 01/ 201434
          </p>
        </v-col>
        <v-col class="d-none d-md-inline" md="5">
          <v-img src="@/assets/illustrations/about_us/building.png"></v-img>
        </v-col>
      </v-row>
    </BaseTransitionWrapper>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
