<template>
  <v-row
    class="d-flex flex-row justify-start justify-md-center align-center"
    v-on="$listeners"
    v-bind="$attrs"
    style="cursor: pointer"
  >
    <span class="primary--text">{{ this.title }}</span>
    <v-avatar class="mx-4 my-4" color="accent">
      <v-icon color="light">mdi-plus</v-icon>
    </v-avatar>
  </v-row>
</template>

<script>
export default {
  props: { title: String },
};
</script>

<style scoped></style>
