<template>
    <BaseDialog :showCondition=show @closed="closeDialog()">
        <template v-slot:title>
           <div class="text-center">
            أنماط الشخصيات المهنية السائدة لديك ودرجتك من كل نمط
           </div> 
        </template>
        <template v-slot:body>
            <v-container>
                <v-row justify="center">
                    <v-col align="center">
                        <v-row v-for="(key) in testResult.getTopTypesCodes()" :key="key" justify="center"
                            class="mt-5" :id="key">
                            <v-col align="start" align-self="center" cols="10">
                                <h3 class="mb-5 secondary--text"> {{ careerTypes[key].title }} ({{ testResult.getTypePercentage(key) }} % ) </h3>
                                <div>
                                    {{ careerTypes[key].description }}
                                </div>
                                <div class="primary--text">
                                    {{ careerTypes[key].professions }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </BaseDialog>
</template>

<script>
import BaseDialog from '@/core/base/dialogs/BaseDialog.vue';
import { mapState } from 'pinia';
import { useCareerAptitudeTestStore } from '../store/CareerAptitudeTestStore';
import careerTypes from '../components/statics/CareerTypes';

export default {
    components: { BaseDialog },
    props: {
        showCondition: Boolean,
    },
    watch: {
        showCondition: {
            immediate: true,
            handler() {
                this.show = this.showCondition;
            },
        },
    },
    computed:{
        ...mapState(useCareerAptitudeTestStore,['testResult'])
    },
    data() {
        return {
            show: this.showCondition,
            careerTypes:careerTypes,
        }
    },
    methods: {
        closeDialog() {
            this.show = false;
            this.$emit("closed");
        },
    }
}
</script>