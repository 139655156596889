<template>
  <BaseDialog
    :ltr="selectedLanguage == 'en'"
    :showCondition="show"
    @closed="closeDialog()"
  > 
    <template v-slot:title> {{ title }} </template>
    <template v-slot:body>
      <div>
        <v-form ref="form">
          <BaseTextField
            v-model="skill.title"
            :rules="[requiredRule]"
            :label="skillName"
            :placeholder="skillPlaceholder"
            @keydown.enter="validateThenAddSkill()"
          >
          </BaseTextField>

          <p>{{ addSkillQuestion }}</p>
          <v-input :value="skill.proficiency" :rules="[requiredRule]">
            <v-rating
              v-model="skill.proficiency"
              full-icon="mdi-circle"
              empty-icon="mdi-circle-outline"
              length="5"
            ></v-rating>
          </v-input>
          <input />
        </v-form>
      </div>
    </template>

    <template v-slot:actions>
      <v-btn color="accent" rounded @click="validateThenAddSkill()">
        {{ add }}
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import ProfessionalSkill from "../../models/ProfessionalSkill";
import ResumeValidationMixin from "../ResumeValidationMixin.vue";

export default {
  mixins:[ResumeValidationMixin],
  data() {
    return {
      show: this.showCondition,
      skill: new ProfessionalSkill(),
    };
  },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume", "selectedLanguage"]),
    title() {
      return this.$t("add_professional_skill");
    },
    add() {
      return this.$t("add");
    },
    skillName() {
      return this.$t("skill_name");
    },
    skillPlaceholder() {
      return this.$t("ph_pro_skill");
    },
    addSkillQuestion() {
      return this.$t("q_add_skill");
    },
  },
  methods: {
    reset() {
      this.skill = new ProfessionalSkill();
      this.$refs.form.reset();
    },
    closeDialog() {
      this.reset();
      this.show = false;
      this.$emit("closed");
    },
    validateThenAddSkill() {
      if (this.$refs.form.validate()) {
        this.resume.professionalSkills.push(this.skill);
        this.closeDialog();
      }
    },
  },
};
</script>
