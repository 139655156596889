<template>
  <div>
    <v-tooltip bottom :open-delay="100">
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          color="accent"
          :class="[
            'floating-btn-tile',
            {
              'tile-pos-sm': $vuetify.breakpoint.smAndDown,
              'tile-pos-md': $vuetify.breakpoint.mdAndUp,
            },
          ]"
          depressed
          height="22"
          :small="$vuetify.breakpoint.smAndDown"
          @click="redirectToAboutUsDonation()"
          >تبرّع</v-btn
        >
        <v-btn
          v-on="{ ...tooltip }"
          :class="[
            'mx-2 floating-btn',
            {
              'pos-sm': $vuetify.breakpoint.smAndDown,
              'pos-md': $vuetify.breakpoint.mdAndUp,
            },
          ]"
          fab
          dark
          :large="$vuetify.breakpoint.mdAndUp"
          :small="$vuetify.breakpoint.smAndDown"
          color="accent"
          @click="redirectToAboutUsDonation()"
          ><v-icon>mdi-hand-heart</v-icon>
        </v-btn>
      </template>
      <span>تبرّع</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToAboutUsDonation() {
      this.$router.push({ path: "/about-us", hash: "donation" });
    }
  }
};
</script>

<style scoped>
.floating-btn {
  position: fixed;
  top: 50%;
  z-index: 2;
}
.pos-sm{
  left: 30px;
}
.pos-md{
  left: 50px;
}
.floating-btn-tile {
  position: fixed;
  left: 0px;
  z-index: 1;
}
.tile-pos-md{
  top: calc(50% + 18px);
}
.tile-pos-sm{
  top: calc(50% + 10px);
}
</style>
