import BasePaginatedCollection from "../../../core/classes/base/BasePaginatedCollection";
import News from "./News";

export default class PaginatedNewsCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    if (paginationResponse != undefined) {
      paginationResponse?.data.map((news) => {
        this.collection.push(new News(news));
      });
    }
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    // TODO: shall we update the collection ?? after implementing pagination with next/previous btns, it's not needed anymore
    this.collection = new Array();
    paginationResponse?.data.map((news) => {
      this.collection.push(new News(news));
    });
  }
}
