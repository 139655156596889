<template>
  <div>
    <!-- Large Screens -->
    <img
      v-if="!rightDecorationOnly"
      class="d-none d-lg-inline decoration left"
      src="@/assets/illustrations/decorations/lg.svg"
    />
    <img
      class="d-none d-lg-inline decoration right"
      src="@/assets/illustrations/decorations/lg.svg"
    />
    <!-- Medium Screens -->
    <img
      v-if="!rightDecorationOnly"
      class="d-none d-md-inline d-lg-none decoration left"
      src="@/assets/illustrations/decorations/md.svg"
    />
    <img
      class="d-none d-md-inline d-lg-none decoration right"
      src="@/assets/illustrations/decorations/md.svg"
    />
    <!-- Small Screens -->
    <img
      v-if="!rightDecorationOnly"
      class="d-none d-sm-inline d-md-none decoration left"
      src="@/assets/illustrations/decorations/sm.svg"
    />
    <img
      class="d-none d-sm-inline d-md-none decoration right"
      src="@/assets/illustrations/decorations/sm.svg"
    />
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseDecoratorWrapper",
  computed: {
    contentClasses() {
      return {
        "m-lg": this.$vuetify.breakpoint.lgAndUp,
        "m-md": this.$vuetify.breakpoint.md,
        "m-sm": this.$vuetify.breakpoint.sm,
        "content-class": true,
      };
    },
  },
  props: {
    rightDecorationOnly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.decoration {
  position: fixed;
  top: 100px;
  width: auto;
  height: 100%;
  object-fit: contain;
}
.left {
  left: 0;
}
.right {
  right: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.m-lg {
  margin: 0 175px;
}
.m-md {
  margin: 0 175px;
}
.m-sm {
  margin: 0 100px;
}
</style>
