<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title> تقدم على هذه الفرصة </template>
    <template v-slot:body>
      <small>يمكنك العودة لهذه الفرصة لاحقاً </small>
      <v-form ref="form">
        <v-file-input
          style="max-width: 400px"
          class="mt-5"
          v-model="resumeFile"
          show-size
          label=" السيرة الذاتية"
          placeholder="أرفق سيرتك الذاتية"
          outlined
          persistent-placeholder
          prepend-icon="mdi-file-account-outline"
          accept="image/jpeg,image/gif,image/png,application/pdf,.docx"
          :rules="[requiredRule]"
        >
        </v-file-input>
        <a target="_blank" href="/create-resume">
          ليس لديك سيرة ذاتية؟ قم بإنشائها من هنا</a
        >
        <v-checkbox
          v-model="isOrgGraduate"
          label="هل أنت متخرج من أحد البرامج التدريبية للجمعية؟"
        ></v-checkbox>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn id="send-application-btn" color="accent" rounded @click="applyToJob()"
        >التقدم على الفرصة</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script>
import { useJobOpportunityStore } from "../store/JobOpportunityStore";
import { mapActions } from 'pinia';

export default {
  props: {
    showCondition: Boolean
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      }
    }
  },
  data() {
    return {
      resumeFile: null,
      isOrgGraduate: false,
      show: this.showCondition
    };
  },
  methods: {
    ...mapActions(useJobOpportunityStore,['sendJobApplication']),
    closeDialog() {
      this.$refs.form.reset();
      this.show = false;
      this.$emit("closed");
    },
    applyToJob() {
      if (this.$refs.form.validate()) {
        var applicationData = { resume: this.resumeFile,is_organization_graduate:Number(this.isOrgGraduate) };
        var jobId = this.$route.params.id;
        this.sendJobApplication(jobId,applicationData)
          .then(() => this.closeDialog())
          .catch(() => {});
      }
    }
  },
};
</script>
