<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title> تغيير كلمة السر </template>
    <template v-slot:body>
      <v-form ref="form">
        <v-container>
          <v-row justify="center">
            <v-col align="center" cols="9">
              <!--Old password-->
              <BasePasswordField
                v-model="oldPassword"
                label="كلمة السر الحالية"
                name="كلمة السر الحالية"
                type="password"
                :rules="[requiredRule, passwordRule]"
                @keyup.enter="validateThenChangePassword()"
              >
            </BasePasswordField>

              <!--New password-->
              <BasePasswordField
                v-model="newPassword"
                label="كلمة السر الجديدة"
                name="كلمة السر الجديدة"
                type="password"
                :rules="[requiredRule, passwordRule]"
                @keyup.enter="validateThenChangePassword()"
              >
              </BasePasswordField>

              <!--Confirm new password-->
              <BasePasswordField
                v-model="confirmNewPassword"
                label="تأكيد كلمة السر الجديدة"
                name="تأكيد كلمة السر الجديدة"
                type="password"
                :rules="[
                  requiredRule,
                  passwordRule,
                  confirmPasswordRule(newPassword),
                ]"
                @keyup.enter="validateThenChangePassword()"
              >
              </BasePasswordField>

              <v-btn
                @click="validateThenChangePassword()"
                class="accent rounded-xl mt-5 px-5"
                >تغيير كلمة السر</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions } from "pinia";
import { useProfileStore } from "../store/ProfileStore";

export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      show: this.showCondition,
    };
  },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  methods: {
    ...mapActions(useProfileStore, ["changePassword"]),
    closeDialog() {
      this.$refs.form.reset();
      this.show = false;
      this.$emit("closed");
    },
    validateThenChangePassword() {
      if (this.$refs.form.validate()) {
        var changePasswordData = {
          old_password: this.oldPassword,
          new_password: this.newPassword,
        };
        this.changePassword(changePasswordData)
          .then(() => this.closeDialog())
          .catch(() => {});
      }
    },
  },
};
</script>
