<template>
  <div>
    <v-responsive class="frame-border" :aspect-ratio="16 / 9">
      <div v-show="showPoster" class="container">
        <v-img
          src="@/assets/thumbnails/video-thumbnail.png"
          lazy-src="@/assets/logos/vector-logo.svg"
          class="blur-img"
        ></v-img>
        <v-btn
          :small="$vuetify.breakpoint.xsOnly"
          fab
          elevation="15"
          color="accent"
          class="ab-btn"
          @click="showPoster = false"
        >
          <v-icon dark>mdi-play</v-icon>
        </v-btn>
      </div>

      <video
        @play="emitFormChanged()"
        :key="videoUrl"
        @ended="checkVideoWatchedFully()"
        v-show="!showPoster"
        id="program_video"
        ref="program_video"
        style="width: 100%; height: auto"
        controls
        preload="metadata"
      >
        <source :src="videoUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </v-responsive>
    <div class="my-5">
      <v-alert v-if="!finishedVideoWatching" dense outlined type="warning">
        عليك مشاهدة كامل الفيديو
      </v-alert>
      <v-alert v-else dense outlined type="success">
        لقد أكملت مشاهدة الفيديو
      </v-alert>
    </div>
    <!-- <NextButton :disabled="!finishedVideoWatching" class="mt-5" @click="completeStep()" /> -->
    <NextButton class="mt-5" @click="validateCompletionThenCompleteStep()" />
  </div>
</template>

<script>
import NextButton from "../buttons/NextButton.vue";
import { EventBus } from "@/main";
import { Notify } from "@/core/classes/notify/Notify";

export default {
  props: {
    videoUrl: String,
  },
  components: {
    NextButton,
  },
  data() {
    return {
      showPoster: true,
      finishedVideoWatching: null,
    };
  },
  mounted() {
    this.checkVideoWatchedFullyPeriodically();
  },
  methods: {
    completeStep() {
      this.$emit("completed");
    },
    changeVideoCompletedStatus() {
      this.finishedVideoWatching = true;
    },
    checkVideoWatchedFully() {
      var video = this.$refs.program_video;
      if (video?.played.end(0) - video?.played.start(0) >= video?.duration - 2.5) {
        this.changeVideoCompletedStatus();
      } else {
        // console.log("Some parts were skipped");
      }
    },
    checkVideoWatchedFullyPeriodically() {
      setInterval(function () {this.checkVideoWatchedFully()}.bind(this), 500);
    },
    validateCompletionThenCompleteStep() {
      if (this.finishedVideoWatching) this.completeStep();
      else
        Notify.showMessage(
          "warning",
          "عليك مشاهدة كامل الفيديو بدون اقتطاع أي جزء منه لتتمكن من الانتقال للخطوة التالية"
        );
    },
    emitFormChanged() {
      EventBus.$emit("formChanged");
    },
  },
};
</script>
<style scoped>
.frame-border {
  border-radius: 15px;
  border: 2px solid #603912;
}

.blur-img {
  filter: blur(10px);
  -webkit-filter: blur(10px);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ab-btn {
  position: absolute;
  z-index: 1;
}
</style>
