<template>
  <BaseDialog
    :ltr="selectedLanguage == 'en'"
    :showCondition="show"
    @closed="closeDialog()"
  >
    <template v-slot:title> {{title}} </template>
    <template v-slot:body>
      <v-form ref="form">
        <BaseTextField
          v-model="work.title"
          :rules="[requiredRule]"
          :label="jobTitle"
          :placeholder="jobTitlePlaceholder"
        >
        </BaseTextField>

        <BaseTextField
          v-model="work.company"
          :rules="[requiredRule]"
          :label="company"
          :placeholder="companyPlaceholder"
        >
        </BaseTextField>

        <BaseTextField
          v-model="work.location"
          :label="location"
          :placeholder="locationPlaceholder"
        >
        </BaseTextField>

        <BaseTextArea
          v-model="work.description"
          :label="description"
          :placeholder="jobDescriptionPlaceholder"
        >
        </BaseTextArea>

        <v-checkbox
          class="ma-0 pa-0"
          v-model="work.present"
          :label="currentlyWorking"
        ></v-checkbox>

        <DateField
           v-model="work.startDate"
          :rules="[requiredRule]"
          :label="startDate"
          type="month"
          @changed="(date) => updateStartDate(date)"
        />

        <div v-show="!work.present">
          <DateField
            v-model="work.endDate"
            :label="endDate"
            type="month"
            :rules="work.present ? [] : [requiredRule]"
            @changed="(date) => updateEndDate(date)"
          />
        </div>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-btn color="accent" rounded @click="validateThenAddExperience()">
        {{ add }}
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import ResumeValidationMixin from "../ResumeValidationMixin.vue";
import WorkExperience from "../../models/WorkExperience";
import DateField from "../DateField.vue";

export default {
  mixins:[ResumeValidationMixin],
  components: { DateField },
  data() {
    return {
      show: this.showCondition,
      work: new WorkExperience(),
    };
  },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume", "selectedLanguage"]),
    title() {
      return this.$t("add_work_experience");
    },
    add() {
      return this.$t("add");
    },
    jobTitle() {
      return this.$t("job_title");
    },
    company() {
      return this.$t("company");
    },
    location() {
      return this.$t("location");
    },
    currentlyWorking() {
      return this.$t("currently_working");
    },
    startDate() {
      return this.$t("start_date");
    },
    endDate() {
      return this.$t("end_date");
    },
    jobTitlePlaceholder() {
      return this.$t("ph_job_title");
    },
    companyPlaceholder() {
      return this.$t("ph_company");
    },
    locationPlaceholder() {
      return this.$t("ph_location");
    },
    jobDescriptionPlaceholder() {
      return this.$t("ph_job_description");
    },
    description() {
      return this.$t("description");
    },
  },
  methods: {
    updateStartDate(date) {
      this.work.startDate = date;
    },
    updateEndDate(date) {
      this.work.endDate = date;
    },
    reset() {
      this.work = new WorkExperience();
      this.$refs.form.reset();
    },
    closeDialog() {
      this.reset();
      this.show = false;
      this.$emit("closed");
    },
    validateThenAddExperience() {
      if (this.$refs.form.validate()) {
        this.resume.workExperiences.push(this.work);
        this.closeDialog();
      }
    },
  },
};
</script>
