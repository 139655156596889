<template>
  <div>
    <!--circular background-->
    <div class="circle circle-1 d-none d-md-flex"></div>
    <div class="circle circle-2"></div>
    <div class="circle circle-3"></div>
    <v-container>
      <v-row justify="center">
        <v-col align-self="center" cols="12" sm="8" md="5">
          <strong>
            <div class="secondary--text" style="font-size: x-large">
              خطوات التسجيل على منحة تدريبية
            </div>
          </strong>
          <!--steps-->
          <v-timeline align-top dense>
            <v-timeline-item
              v-for="step in steps"
              :key="step.num"
              color="accent"
              small
            >
              <template v-slot:icon>
                <span class="dark">{{ step.num }}</span>
              </template>
              <v-row class="pt-1">
                <v-col>
                  {{ step.label }}
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
          <!--available training program-->
          <v-col cols="10" md="9">
            <v-form ref="selection">
              <v-select
                :rules="[requiredRule]"
                v-model="selectedProgramId"
                height="25"
                rounded
                chips
                clearable
                item-value="id"
                :items="trainingPrograms"
                label="المسار المهني"
                outlined
              >
                <template v-slot:item="{ item }">
                  {{ item.label }}
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip>{{ item.label }}</v-chip>
                </template>
              </v-select>
            </v-form>
          </v-col>
          <!--program suggestion area, trainers requests, and start application btn -->
          <div>
            لم تجد المسار المهني التي ترغب به؟
            <v-btn text color="accent" @click.prevent="openSuggestionDialog()"
              >اقتراح مساراً</v-btn
            >
          </div>

          <v-btn
            class="mt-5 mb-2"
            color="accent"
            rounded
            @click="openTermsDialog()"
            >ابدأ التسجيل</v-btn
          >
        
        </v-col>
        <v-col cols="0" md="5" lg="4" class="d-none d-md-flex">
          <v-img
            transition="scale-transition"
            src="@/assets/illustrations/training_programs/training-intro.png"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <!--dialogs(suggestion/terms&conditions)-->
    <TrainingProgramSuggestionDialog
      :showCondition="showSuggestionDialog"
      @closed="closeSuggestionDialog()"
    />
    <TrainingTermsNConditionsDialog
      :selectedTrainingProgramId="selectedProgramId"
      :trainingPrograms="trainingPrograms"
      :showCondition="showTermsDialog"
      @closed="closeTermsDialog()"
      @agreed="redirectToApplicationForm()"
    />
  </div>
</template>
<script>
import TrainingProgramSuggestionDialog from "../components/TrainingProgramSuggestionDialog.vue";
import TrainingTermsNConditionsDialog from "../components/TrainingTermsNConditionsDialog.vue";
import applicationSteps from "../components/statics/ِApplicationSteps";
import { mapActions, mapState } from "pinia";
import { useTrainingProgramsStore } from "../store/TrainingProgramsStore";
import { Notify } from "@/core/classes/notify/Notify";
export default {
  components: {
    TrainingProgramSuggestionDialog,
    TrainingTermsNConditionsDialog,
  },
  mounted() {
    Promise.all(this.fetchTrainingPrograms(), this.fetchSuggestingStatus())
      .then()
      .catch((err) => {
        err;
      });
  },
  computed: {
    ...mapState(useTrainingProgramsStore, [
      "trainingPrograms",
      "suggestingIsEnabled",
    ]),
  },
  data() {
    return {
      showSuggestionDialog: false,
      showTermsDialog: false,
      steps: applicationSteps,
      selectedProgramId: null,
    };
  },
  methods: {
    ...mapActions(useTrainingProgramsStore, [
      "fetchTrainingPrograms",
      "fetchSuggestingStatus",
    ]),
    openSuggestionDialog() {
      if (this.suggestingIsEnabled) this.showSuggestionDialog = true;
      else Notify.showMessage("warning", "خدمة الاقتراح غير متاحة حالياً");
    },
    closeSuggestionDialog() {
      this.showSuggestionDialog = false;
    },
    openTermsDialog() {
      if (this.$refs.selection.validate()) this.showTermsDialog = true;
    },

    closeTermsDialog() {
      this.showTermsDialog = false;
    },
   
    redirectToApplicationForm() {
      this.$router.push(
        "/training-programs/" + this.selectedProgramId + "/apply"
      );
    },
  },
};
</script>
<style scoped>
.circle {
  position: fixed;
  left: -200px;
  top: -200px;
  border-radius: 50%;
  background-color: #f7941d;
  z-index: 0;
  opacity: 0.2;
}

.circle-1 {
  width: 1000px;
  height: 1000px;
  animation: circle-animation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.circle-2 {
  width: 750px;
  height: 750px;
  animation: circle-animation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}

.circle-3 {
  width: 500px;
  height: 500px;
  animation: circle-animation;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes circle-animation {
  0% {
    top: -1346px;
    left: -1075px;
  }

  100% {
    left: -200px;
    top: -200px;
  }
}
</style>
