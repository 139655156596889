<template>
  <div>
    <v-tooltip bottom :open-delay="100">
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          v-on="{ ...tooltip }"
          :class="[
            'mx-2 floating-btn-2',
            {
              'pos-sm': $vuetify.breakpoint.smAndDown,
              'pos-md': $vuetify.breakpoint.mdAndUp,
            },
          ]"
          fab
          dark
          small
          color="accent"
          @click="openRatingDialog()"
          ><v-icon>mdi-message-draw</v-icon></v-btn
        >
      </template>
      <span> أرسل رأيك</span>
    </v-tooltip>
    <RatingDialog
      :showCondition="showRatingDialog"
      @closed="closeRatingDialog()"
    />
  </div>
</template>

<script>
import RatingDialog from "../components/RatingDialog.vue";

export default {
  components: {
    RatingDialog,
  },
  data() {
    return {
      showRatingDialog: false,
    };
  },
  methods: {
    openRatingDialog() {
      this.showRatingDialog = true;
    },
    closeRatingDialog() {
      this.showRatingDialog = false;
    },
  },
};
</script>

<style scoped>
.floating-btn-2 {
  position: fixed;
  z-index: 2;
}
.pos-md {
  left: 50px;
  bottom: 50px;
}
.pos-sm {
  left: 1px;
  bottom: 25px;
}
</style>
