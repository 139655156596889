<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col class="d-flex flex-column align-start" cols="12" md="8">
        <v-col class="d-flex flex-column align-center">
          <h1 class="primary--text text-center">{{ obj.title }}</h1>
          <v-carousel
            class="mt-10 rounded-xl carousel"
            v-if="obj.medias.length"
            v-model="current"
            height="350px"
            hide-delimiters
            continuous
          >
            <v-carousel-item v-for="(media, i) in obj.medias" :key="i">
              <!-- Case media is image -->
              <v-img
                v-if="media.type == 'image'"
                class="media"
                :src="media.localPath"
                lazy-src="@/assets/logos/brown-logo.svg"
                contain
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      color="accent"
                      indeterminate
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <!-- Case media is a local video -->
              <video v-else-if="media.type == 'video'" class="media" controls>
                <source :src="media.localPath" />
              </video>

              <!-- Case media is a facebook video -->
              <template v-else-if="media.externalPlatform == 'Facebook'">
                <LoadableIframe
                  :src="
                    'https://www.facebook.com/plugins/video.php?href=' +
                    media.externalLink +
                    '&show_text=false&t=0'
                  "
                />
              </template>

              <!-- Case media is a youtube video -->
              <LoadableIframe
                v-else
                :src="getEmbedYoutubeLink(media.externalLink)"
              />
            </v-carousel-item>
          </v-carousel>
          <!--Custom delimiters -->
          <div class="custom-delimiters mt-2 mb-5">
            <v-btn
              v-for="(item, i) in obj.medias"
              :key="i"
              @click="goTo(i)"
              icon
            >
              <v-icon small>{{
                i === current ? "mdi-circle" : "mdi-circle-outline"
              }}</v-icon>
              <transition name="fade">
                <div v-if="i === current" class="point"></div>
              </transition>
            </v-btn>
          </div>
        </v-col>

        <p>{{ obj.description }}</p>
        <template v-if="!isNews">
          <p>
            <span class="secondary--text">اسم المنجز : </span
            >{{ obj.accomplisherName }}
          </p>
          <p>
            <span class="secondary--text"
              >اسم البرنامج التدريبي الذي يتبع المشروع له : </span
            >{{ obj.trainingProgramName }}
          </p>
          <a
            v-if="obj.githubLink"
            :href="obj.githubLink"
            target="_blank"
            class="my-2"
          >
            <v-icon color="secondary">mdi-github</v-icon> رابط المشروع على
            GitHub
          </a>
          <a
            v-if="obj.behanceLink"
            :href="obj.behanceLink"
            target="_blank"
            class="my-2"
          >
            <v-icon color="secondary">mdi-web</v-icon> رابط المشروع على Behance
          </a>
        </template>
        <v-container>
          <small v-if="isNews" class="date">{{ obj.createdAt }} </small>
          <small v-else class="date">{{ obj.accomplishmentDate }} </small>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoadableIframe from "./LoadableIframe.vue";

export default {
  props: {
    obj: Object,
    isNews: Boolean,
  },
  data() {
    return {
      current: 0,
    };
  },
  methods: {
    getEmbedYoutubeLink(link) {
      let videoId;
      if (link.includes("youtu.be")) {
        videoId = link.split(".be/")[1];
      } else {
        videoId = link.split("v=")[1];
      }
      return "https://www.youtube.com/embed/" + videoId;
    },
    goTo(i) {
      this.current = i;
    },
  },
  components: { LoadableIframe },
};
</script>

<style>
.carousel {
  border: 2px solid var(--v-primary-base);
  max-width: 600px;
}
.date {
  color: grey;
}
.media {
  width: 100%;
  height: 100%;
}

.custom-delimiters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-delimiters v-btn {
  width: 32px;
  height: 32px;
  position: relative;
}
.point {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--v-secondary-base);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
