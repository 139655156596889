import Vue from "vue";
import App from "./App.vue";
import { PiniaVuePlugin } from "pinia";
import VueCompositionAPI from "@vue/composition-api";
import pinia from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import Client from "./core/classes/client/Client";
import Notifications from "vue-notification";
import router from "./router";
import "@/core/base/GlobalBaseComponentsRegistration";
import FormValidationRulesMixinVue from "./core/mixins/FormValidationRulesMixin.vue";
import t from "@/modules/create_resume/locale/t.js";

//initializing http client with interceptors
window.axios = Client.initHttp(axios);

//registering dependencies
Vue.use(PiniaVuePlugin);
Vue.use(VueCompositionAPI);
Vue.use(Notifications);

//register mixins
Vue.mixin(FormValidationRulesMixinVue);

//register methods
Vue.prototype.$t = t; 

Vue.config.productionTip = false;

//creating event bus
export const EventBus = new Vue();


//mounting vue instance
new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
