export default class BasePaginatedCollection {
  collection = new Array();
  total;
  perPage;
  currentPage;
  lastPage;
  isLastPage;

  constructor(
    paginationResponse //! this should be overridden in concretes (specific object collection init)
  ) {
    if (paginationResponse == undefined) {
      //case of empty parameter constructor
      this.currentPage = 1;
      this.isLastPage = true;
    } else {
      this.total = paginationResponse.total;
      this.perPage = paginationResponse.per_page;
      this.currentPage = paginationResponse.current_page;
      this.lastPage = paginationResponse.last_page;
      this.isLastPage = this.currentPage == this.lastPage;
    }
  }
  //! this should be overridden in concretes  (specific object collection update)
  updatePaginationPage(paginationResponse) {
    this.total = paginationResponse.total;
    this.perPage = paginationResponse.per_page;
    this.currentPage = paginationResponse.current_page;
    this.lastPage = paginationResponse.last_page;
    this.isLastPage = this.currentPage == this.lastPage;
  }
}
