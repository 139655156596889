<template>
  <BaseDecoratorWrapper>
    <v-container transition="fade-transition">
      <v-row justify="center">
        <v-col align-self="center" cols="12" lg="9">
          <v-stepper v-model="currentMainStep">
            <v-stepper-header>
              <v-stepper-step color="accent" :complete="currentMainStep > 1" step="1">
                <small>
                  مشاهدة الفيديو التعريفي
                </small>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step color="accent" :complete="currentMainStep > 2" step="2">
                <small>
                  تعبئة بيانات التسجيل
                </small>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step color="accent" :complete="currentMainStep > 3" step="3">
                <small>
                  إجراء اختبار الميول المهني
                </small>
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <h4 class="secondary--text my-3">
                  مشاهدة الفيديو التعريفي
                </h4>
                <VideoStep :videoUrl="appliedTrainingProgram.videoURL" @completed="currentMainStep = 2" />
              </v-stepper-content>

              <v-stepper-content step="2">
                <h4 class="secondary--text my-3">
                  تعبئة بيانات التسجيل
                </h4>
                <ApplicantInfoStep @completed="currentMainStep = 3" />
              </v-stepper-content>

              <v-stepper-content step="3">
                <h4 class="secondary--text my-3">
                  إجراء اختبار الميول المهني
                </h4>
                <CareerTestStep @completed="sendApplication($event)" />
              </v-stepper-content>

              <v-stepper-content step="4">
                <ApplicationSentStep />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </BaseDecoratorWrapper>
</template>
<script>

import ApplicationSentStep from "../components/application_steps/ApplicationSentStep.vue";
import CareerTestStep from "../components/application_steps/CareerTestStep.vue";
import VideoStep from "../components/application_steps/VideoStep.vue";
import ApplicantInfoStep from "../components/application_steps/ApplicantInfoStep.vue";
import { mapActions, mapState } from "pinia";
import { useApplicationFormStore } from "../store/ApplicationFormStore";
import { EventBus } from "@/main";

export default {
  components: {
    VideoStep,
    ApplicantInfoStep,
    CareerTestStep,
    ApplicationSentStep,
  },
  data() {
    return {
      currentMainStep: 1,
    };
  },
  computed: {
    ...mapState(useApplicationFormStore, ["appliedTrainingProgram"]),
  },
  mounted() {
    var trainingProgramId = this.$route.params.id;
    this.fetchTrainingProgram(trainingProgramId)
      .then()
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    ...mapActions(useApplicationFormStore, [
      "fetchTrainingProgram",
      "postTrainingProgramApplication",
    ]),
    sendApplication(testResultId) {
      var trainingProgramId = this.$route.params.id;
      this.postTrainingProgramApplication(trainingProgramId, testResultId)
        .then(() => {
          EventBus.$emit("applicationSubmitted");
          this.currentMainStep = 4;
        })
        .catch();
    },
  },
};
</script>

<style scoped>
::v-deep .v-stepper__step__step {
  width: 40px;
  height: 40px;
}
</style>
