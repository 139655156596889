import Vue from "vue";


const requireComponent = require.context("./", true, /Base[\w-]+\.vue$/);

requireComponent.keys().forEach(fileName => {
  // get component config
  const componentConfig = requireComponent(fileName);
  // register base components globally
  Vue.component(
    componentConfig.default.name,
    componentConfig.default || componentConfig
  );
});
