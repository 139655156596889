<template>
  <v-col cols="12" md="4" align="center" justify="center">
    <AddButton :title="this.$t('soft_skills')" @click="openDialog()" />
    <BaseDraggable v-model="resume.softSkills">
      <DeletableCard
        v-for="(skill, i) in resume.softSkills"
        :key="i"
        :index="i"
        name="softSkills"
      >
        <span class="primary--text">{{ skill.title }}</span>
      </DeletableCard>
    </BaseDraggable>
    <AddSoftSkillsDialog :showCondition="showDialog" @closed="closeDialog()" />
  </v-col>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";

import AddButton from "../buttons/AddButton.vue";
import AddSoftSkillsDialog from "../dialogs/AddSoftSkillsDialog.vue";
import DeletableCard from "../DeletableCard.vue";

export default {
  components: {
    AddButton,
    AddSoftSkillsDialog,
    DeletableCard,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style></style>
