<template>
  <BaseDialog
    :ltr="selectedLanguage == 'en'"
    :showCondition="show"
    @closed="closeDialog()"
  >
    <template v-slot:title> {{ title }} </template>
    <template v-slot:body>
      <v-form ref="form">
        <BaseTextField
          v-model="language.name"
          :rules="[requiredRule]"
          :label="languageName"
          :placeholder="languagePlaceholder"
          @keydown.enter="validateThenAddSkill()"
        >
       </BaseTextField>

        <p>{{ addLanguageQs }}</p>
        <v-input :value="language.proficiency" :rules="[requiredRule]">
          <v-rating
            v-model="language.proficiency"
            full-icon="mdi-circle"
            empty-icon="mdi-circle-outline"
            length="5"
          ></v-rating>
        </v-input>
        <input />
      </v-form>
    </template>

    <template v-slot:actions>
      <v-btn color="accent" rounded @click="validateThenAddSkill()">
        {{ add }}
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { mapWritableState } from "pinia";
import { useCreateResumeStore } from "../../store/CreateResumeStore";
import ResumeValidationMixin from "../ResumeValidationMixin.vue";
import Language from "../../models/Language";

export default {
  mixins:[ResumeValidationMixin],
  data() {
    return {
      show: this.showCondition,
      language: new Language(),
    };
  },
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  computed: {
    ...mapWritableState(useCreateResumeStore, ["resume", "selectedLanguage"]),
    title() {
      return this.$t("add_language");
    },
    add() {
      return this.$t("add");
    },
    languageName() {
      return this.$t("language");
    },
    languagePlaceholder() {
      return this.$t("ph_language");
    },
    addLanguageQs() {
      return this.$t("q_add_language");
    },
  },

  methods: {
    reset() {
      this.language = new Language();
      this.$refs.form.reset();
    },
    closeDialog() {
      this.reset();
      this.show = false;
      this.$emit("closed");
    },
    validateThenAddSkill() {
      if (this.$refs.form.validate()) {
        this.resume.languages.push(this.language);
        this.closeDialog();
      }
    },
  },
};
</script>
