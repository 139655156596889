<template>
  <div id="result-template" class="display-none">
    <v-app>
      <div class="ma-15" style="position: relative; min-height: 1000px">
        <img class="logo" src="@/assets/logos/vector-logo.png" />
        <h3 class="text-center primary--text">حسب نتيجة اختبارك كانت الأنماط السائدة لديك:</h3>
        <p
          class="accent--text"
          v-for="(value, key) in testResult.getFirstPrevalentTypes()"
          :key="key"
        >
          {{ careerTypes[value].title }}:
          <span class="secondary--text">
            {{ careerTypes[value].professions }},
            {{ careerTypes[value].description }}
          </span>
        </p>
        <div v-if="testResult.getSecondPrevalentTypes().length > 1">
          <h4 class="text-center primary--text">
            كما أنه لديك نسبة عالية من الأنماط التالية:
          </h4>
          <p
            class="accent--text"
            v-for="(value, key) in testResult.getSecondPrevalentTypes()"
            :key="key"
          >
            {{ careerTypes[value].title }}:
            <span class="secondary--text">
              {{ careerTypes[value].professions }},
              {{ careerTypes[value].description }}
            </span>
          </p>
        </div>
        <div class="footer">
          <v-row justify="space-between">
            <v-col align="center">® جمعية البركة للتنمية الاجتماعية </v-col>
            <v-col>1</v-col>
            <v-col align="center">
              <a href="https://www.facebook.com/albarakah.net">
                www.facebook.com/albarakah.net
              </a>
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="ma-15" style="position: relative; min-height: 1000px">
        <img class="logo" src="@/assets/logos/vector-logo.png" />
        <h3 class="my-4 text-center primary--text">
          نصيحة "البركة" للنجاح في الحياة العملية
        </h3>
        <div>
          <v-row align="start" justify="start">
            <v-icon class="mt-3">mdi-check-bold</v-icon>
            <v-col cols="11">
              <strong>
                <span style="font-size: 17px">
                  تعلُّم أخلاقيات مهنتك والالتزام بها
                </span>
              </strong>
              <strong>
                <span style="font-size: 17px"
                  >شرط أساسي للنجاح والتميز: &nbsp;</span
                ></strong
              ><span style="font-size: "
                >توصلت إحدى الدراسات إلى أن 85% من أسباب فشل الموظفين كانت
                اسباباً غير مهنية حيث لم ينجحوا في التعامل مع المعنيين في العمل
                (الإدارة، الزملاء، الزبائن، المرؤوسين، الجوار والمجتمع....الخ)،
                كما أن أصحاب الأعمال والمشغلين يؤكدون أن معيار أخلاقيات المهنة
                أساسي لكسب ثقتهم ويفتح أفقاً كبيراً لتطور الموظف في
                مؤسساتهم.</span
              >
            </v-col>
          </v-row>
        </div>

        <div style="margin: 0in; font-size: 16px">
          <ul style="margin-bottom: 0in; list-style-type: undefined"></ul>
        </div>
        <p
          dir="RTL"
          style="
            margin: 0in;
            font-size: 16px;
            margin-right: 0.25in;
            text-align: right;
          "
        >
          <span style="font-size: 17px"
            ><br />
            أخلاقيات العمل تحقق لك كل هذا فضلاً عن
            <strong>&quot;الكسب الحلال&quot;</strong></span
          >
        </p>
        <p
          dir="RTL"
          style="
            margin: 0in;
            font-size: 16px;
            margin-right: 0.25in;
            text-align: right;
          "
        >
          <span style="font-size: 17px">&nbsp;</span>
        </p>
        <div>
          <v-row align="start" justify="start">
            <v-icon class="mt-3">mdi-check-bold</v-icon>
            <v-col cols="11">
              <strong
                ><span style="font-size: 17px"
                  >الممارسة العملية للمهنة مهمة جداً</span
                ></strong
              ><span style="font-size: 17px"
                >: فاحرص ولو بعمل ذي أجر منخفض أو عمل تطوعي وغير مأجور.</span
              >
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row align="start" justify="start">
            <v-icon class="mt-3">mdi-check-bold</v-icon>
            <v-col cols="11">
              <strong
                ><span style="font-size: 17px"
                  >تعلم المهارات الداعمة:</span
                ></strong
              ><span style="font-size: 17px"
                >&nbsp;الحاسب &ndash; اللغات- المهارات الشخصية فهي التي تحدد
                فرصتك ومستوى العمل الذي ستحصل عليه</span
              >
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row align="start" justify="start">
            <v-icon class="mt-3">mdi-check-bold</v-icon>
            <v-col cols="11">
              <strong
                ><span style="font-size: 17px"
                  >احرص على التعلم الذاتي (</span
                ></strong
              ><strong
                ><span dir="LTR" style="font-size: 17px"
                  >Self-learning</span
                ></strong
              ><strong><span style="font-size: 17px">): </span></strong>
              <span style="font-size: 17px"
                >يتميز هذا النوع من التعلم بأنه غير مكلف، ويضيف لنا خبرات عملية
                مهمة سواء حصلنا على عمل أم لا، ويتيح لنا تحديث وتطوير خبراتنا
                ومهاراتنا باستمرار ، &nbsp;ومن أهم الطرق الميسّرة للتعلم
                الذاتي:</span
              >
            </v-col>
          </v-row>
        </div>
        <div class="mx-6">
          <div>
            <v-row align="start" justify="start">
              <v-icon class="mt-3">mdi-circle-medium</v-icon>
              <v-col cols="11">
                <span style="font-size: 17px"
                  >&nbsp;<strong>منصات التعليم الإلكترونية</strong> التي تقدم
                  مواد علمية ودورات تدريبية متقدمة جداً في بعض الحالات ، إضافة
                  لشهادة حضور إلكترونية لمن يجتاز الاختبارات، وأهم هذه المنصات
                  على سبيل الأمثلة لا الحصر :</span
                >
              </v-col>
            </v-row>
          </div>
          <div class="mt-4" align="center">
            <table dir="rtl" style="border-collapse: collapse; border: none">
              <tbody>
                <tr>
                  <td
                    colspan="2"
                    style="
                      width: 453pt;
                      border: 1pt solid windowtext;
                      vertical-align: top;
                    "
                  >
                    <p class="text-center pa-0 ma-0">
                      منصات تعلم الكترونية باللغتين العربية والانكليزية باللغة
                      العربية
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      width: 225.4pt;
                      border: solid windowtext 1pt;
                      border-top: none;
                      padding: 0in 5.4pt 0in 5.4pt;
                    "
                  >
                    <p
                      style="
                        margin: 0in;
                        font-size: 16px;

                        text-align: left;
                      "
                    >
                      <a href="http://www.edraak.org"
                        ><span>www.edraak.org</span></a
                      >
                    </p>
                  </td>
                  <td
                    style="
                      width: 227.6pt;
                      border-top: none;
                      border-left: solid windowtext 1pt;
                      border-bottom: solid windowtext 1pt;
                      border-right: none;
                      padding: 0in 5.4pt 0in 5.4pt;
                    "
                  >
                    <p dir="LTR" style="margin: 0in; font-size: 16px">
                      <a href="https://www.coursera.org/"
                        >www.coursera.org<span dir="RTL">/</span></a
                      >
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      width: 225.4pt;
                      border: solid windowtext 1pt;
                      border-top: none;
                      padding: 0in 5.4pt 0in 5.4pt;
                    "
                  >
                    <p
                      dir="RTL"
                      style="
                        margin: 0in;
                        font-size: 16px;

                        text-align: left;
                      "
                    >
                      <a href="http://www.rwaq.org"
                        ><span dir="LTR">www.rwaq.org</span></a
                      >&nbsp;
                    </p>
                  </td>
                  <td
                    style="
                      width: 227.6pt;
                      border-top: none;
                      border-left: solid windowtext 1pt;
                      border-bottom: solid windowtext 1pt;
                      border-right: none;
                      padding: 0in 5.4pt 0in 5.4pt;
                    "
                  >
                    <p dir="LTR" style="margin: 0in; font-size: 16px">
                      <a href="https://www.edx.org/">www.edx.org/</a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      width: 225.4pt;
                      border: solid windowtext 1pt;
                      border-top: none;
                      padding: 0in 5.4pt 0in 5.4pt;
                    "
                  >
                    <p
                      dir="RTL"
                      style="
                        margin: 0in;
                        font-size: 16px;

                        text-align: left;
                      "
                    >
                      <a href="http://www.nadrus.com"
                        ><span dir="LTR">www.nadrus.com</span></a
                      >&nbsp;
                    </p>
                  </td>
                  <td
                    style="
                      width: 227.6pt;
                      border-top: none;
                      border-left: solid windowtext 1pt;
                      border-bottom: solid windowtext 1pt;
                      border-right: none;
                      padding: 0in 5.4pt 0in 5.4pt;
                    "
                  >
                    <p dir="LTR" style="margin: 0in; font-size: 16px">
                      <a href="https://www.khanacademy.org/"
                        >www.khanacademy.org/</a
                      >
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      width: 225.4pt;
                      border: solid windowtext 1pt;
                      border-top: none;
                      padding: 0in 5.4pt 0in 5.4pt;
                    "
                  >
                    <p
                      dir="LTR"
                      style="
                        margin: 0in;
                        font-size: 16px;
                        text-align: left;
                      "
                    >
                      <a href="https://academy.hsoub.com/"
                        ><span dir="LTR">academy.hsoub.com</span></a
                      >
                    </p>
                  </td>
                  <td
                    style="
                      width: 227.6pt;
                      border-top: none;
                      border-left: solid windowtext 1pt;
                      border-bottom: solid windowtext 1pt;
                      border-right: none;
                      padding: 0in 5.4pt 0in 5.4pt;
                    "
                  >
                    <p dir="LTR" style="margin: 0in; font-size: 16px">
                      <a href="https://www.udacity.com/">www.udacity.com/</a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      width: 225.4pt;
                      border: solid windowtext 1pt;
                      border-top: none;
                      padding: 0in 5.4pt 0in 5.4pt;
                    "
                  >
                    <p dir="LTR" style="margin: 0in; font-size: 16px">
                      <a href="https://free4arab.net"
                        >https://free<span
                          dir="RTL"
                          style="font-family: 'Arial', sans-serif"
                          >4</span
                        >arab.net</a
                      >
                    </p>
                  </td>
                  <td
                    style="
                      width: 227.6pt;
                      border-top: none;
                      border-left: solid windowtext 1pt;
                      border-bottom: solid windowtext 1pt;
                      border-right: none;
                      padding: 0in 5.4pt 0in 5.4pt;
                    "
                  >
                    <p dir="LTR" style="margin: 0in; font-size: 16px">
                      <a href="https://nouvil.net">https://nouvil.net</a>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style="margin: 0in; font-size: 16px">
            <span dir="RTL">&nbsp;</span>
          </p>

          <p
            dir="RTL"
            style="
              margin: 0in;
              font-size: 16px;
              text-align: center;
            "
          >
            <strong>وهذه بعض المنصات المفيدة جداً في تعلم اللغات</strong>
          </p>
          <div align="center" style="margin: 0in; font-size: 16px">
            <table dir="rtl" style="border-collapse: collapse; border: none">
              <tbody>
                <tr>
                  <td
                    style="
                      width: 120.8pt;
                      border: solid windowtext 1pt;
                      padding: 0in 5.4pt 0in 5.4pt;
                    "
                  >
                    <p
                      dir="RTL"
                      style="
                        margin: 0in;
                        font-size: 16px;
                        text-align: right;
                      "
                    >
                      <a href="http://www.rosettastone.com"
                        ><span dir="LTR">www.rosettastone.com</span></a
                      >&nbsp;
                    </p>
                  </td>
                  <td
                    style="
                      width: 201.8pt;
                      border-top: 1pt solid windowtext;
                      border-bottom: 1pt solid windowtext;
                      border-left: 1pt solid windowtext;
                      border-image: initial;
                      border-right: none;
                      padding: 0in 5.4pt;
                      vertical-align: top;
                    "
                  >
                    <p dir="LTR" style="margin: 0in; font-size: 16px">
                      <a href="http://learnenglish.britishcouncil.org/ar"
                        >http://learnenglish.britishcouncil.org/ar</a
                      ><span dir="RTL">&nbsp;</span>
                    </p>
                  </td>
                  <td
                    style="
                      width: 130.4pt;
                      border-top: 1pt solid windowtext;
                      border-bottom: 1pt solid windowtext;
                      border-left: 1pt solid windowtext;
                      border-image: initial;
                      border-right: none;
                      padding: 0in 5.4pt;
                      vertical-align: top;
                    "
                  >
                    <p dir="LTR" style="margin: 0in; font-size: 16px">
                      <a href="http://www.duolingo.com">www.duolingo.com</a>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <v-row class="mt-2" align="start" justify="start">
              <v-icon class="mt-3">mdi-circle-medium</v-icon>
              <v-col cols="11">
                <strong
                  ><span style="font-size: 17px"
                    >متابعة المواقع والصفحات والمنتديات المتعلقة
                    بمهنتكم.&nbsp;</span
                  ></strong
                >
              </v-col>
            </v-row>
          </div>
        </div>

        <p
          dir="RTL"
          style="
            margin: 0in;
            font-size: 16px;
            margin-right: 40.5pt;
            text-align: right;
          "
        >
          <strong><span style="font-size: 17px">&nbsp;</span></strong>
        </p>
        <p
          dir="RTL"
          style="
            margin: 0in;
            font-size: 16px;
            text-align: right;
          "
        >
          <strong
            ><span style="font-size: 17px"
              >أخيراً:<br />&nbsp;&nbsp;</span
            ></strong
          >
          <span style="font-size: 17px">
            سواء أتيحت الفرصة لوجودكم ضمن برامج الجمعية أم لا فإن جمعية البركة
            وترجو لكم دوام التوفيق والنجاح والمساهمة في تطوير أنفسنا و بلدنا
            ومجتمعنا
          </span>
        </p>
        <p
          dir="RTL"
          style="
            margin: 0in;
            font-size: 16px;
            margin-right: 40.5pt;
            text-align: center;
          "
        >
          <strong>للوصول إلى سوق عمل ناجح يحكّم المعايير الأخلاقية</strong>
        </p>
        <p
          dir="RTL"
          style="
            margin: 0in;
            font-size: 16px;
            margin-top: 0in;
            margin-right: -0.2in;
            margin-bottom: 0.0001pt;
            margin-left: -0.2in;
            text-align: center;
          "
        >
          &nbsp;
        </p>
        <p
          dir="RTL"
          style="
            margin: 0in;
            font-size: 16px;
            margin-top: 0in;
            margin-right: -0.2in;
            margin-bottom: 0.0001pt;
            margin-left: -0.2in;
            text-align: center;
          "
        >
          شكراً
        </p>
        <p
          dir="RTL"
          style="
            margin: 0in;
            font-size: 16px;

            text-align: center;
          "
        >
          <strong>&nbsp;</strong>
        </p>
        <div class="footer">
          <v-row justify="space-between">
            <v-col align="center">® جمعية البركة للتنمية الاجتماعية </v-col>
            <v-col align="center">2</v-col>
            <v-col align="center">
              <a href="https://www.facebook.com/albarakah.net">
                www.facebook.com/albarakah.net
              </a>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useCareerAptitudeTestStore } from "../store/CareerAptitudeTestStore";

import careerTypes from "./statics/CareerTypes";

export default {
  data() {
    return {
      careerTypes: careerTypes,
    };
  },
  computed: {
    ...mapState(useCareerAptitudeTestStore, ["testResult"]),
  },
};
</script>

<style scoped>
.display-none {
  display: none;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  height: 50%;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: 25mm; */
  text-align: center;
  color: gray;
  font-size: 12px;
}
</style>
