export default class Resume {
  name;
  dateOfBirth;
  gender;
  about;
  phoneNumber;
  email;
  residence;
  links = [];
  projects = [];
  languages = [];
  references = [];
  softSkills = [];
  educations = [];
  certificates = [];
  workExperiences = [];
  professionalSkills = [];
  volunteerExperiences = [];
  constructor(data) {
    if (data) {
      this.name = data?.name;
      this.dateOfBirth = data?.dateOfBirth;
      this.gender = data?.gender;
      this.about = data?.about;
      this.phoneNumber = data?.phoneNumber;
      this.email = data?.email;
      this.residence = data?.residence;
      this.links = data?.links ? data.links : [];
      this.projects = data?.projects ? data.projects : [];
      this.languages = data?.languages ? data.languages : [];
      this.references = data?.references ? data.references : [];
      this.softSkills = data?.softSkills ? data.softSkills : [];
      this.educations = data?.educations ? data.educations : [];
      this.certificates = data?.certificates ? data.certificates : [];
      this.workExperiences = data?.workExperiences ? data.workExperiences : [];
      this.professionalSkills = data?.professionalSkills
        ? data.professionalSkills
        : [];
      this.volunteerExperiences = data?.volunteerExperiences
        ? data.volunteerExperiences
        : [];
    }
  }
}
