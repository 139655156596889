import PaginatedFAQCollection from "@/core/classes/models/paginated_collections/PaginatedFAQCollection";
import Tag from "@/core/classes/models/Tag";
import { defineStore } from "pinia";
import HomeService from "../services/HomeService";

export const useFAQsStore = defineStore("FAQsStore", {
  state: () => ({
    tags: [],
    paginatedFAQs: new PaginatedFAQCollection(),
    selectedFilterTag: null
  }),
  getters: {},
  actions: {
    fetchFAQsTags() {
      return new Promise((resolve, reject) => {
        HomeService.getFAQsTags()
          .then(res => {
            let { data, status } = res;
            return HomeService.resolveStatusResponse(status, res).then(() => {
              this.tags=[];//reset tags collection
              data.data.map((tag)=>{
                this.tags.push(new Tag(tag))
              });
              return resolve(res);
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    fetchPaginatedFAQs() {
      return new Promise((resolve, reject) => {
        HomeService.getPaginatedFAQsWithFilter(
          this.selectedFilterTag?.id,
          this.paginatedFAQs.currentPage
        )
          .then(res => {
            let { data, status } = res;
            return HomeService.resolveStatusResponse(status, res).then(() => {
              if (this.paginatedFAQs.collection.length == 0)//case first page
                this.paginatedFAQs = new PaginatedFAQCollection(data);
              else//case collection not empty push new items to it&update pagination
               this.paginatedFAQs.updatePaginationPage(data);

              return resolve(res);
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    //
    fetchNextPage() {
      !this.paginatedFAQs.isLastPage
        ? this.paginatedFAQs.currentPage++
        : console.warn("no more pages!");
      this.fetchPaginatedFAQs();
    },
    //it could be either tag object or undefined in case of filter unselecting
    setFilterStateAndRefetch(selectedFilterTag) {
      this.selectedFilterTag = selectedFilterTag;
      this.resetPaginatedFAQ();//whenever a filter is selected/unselected the paginated collection should be reset
      this.fetchPaginatedFAQs();
    },
    
    resetPaginatedFAQ(){
      this.paginatedFAQs=new PaginatedFAQCollection();
    }
  }
});
