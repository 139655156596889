<template>
  <v-container class="pa-0 ma-0 mb-2" v-if="resume.certificates.length > 0">
    <BaseDivider :title="this.$t('certifications')" />
    <div
      class="pa-0 ma-0 mb-2"
      v-for="(certificate, i) in resume.certificates"
      :key="i"
    >
      <template>
        <h4 class="pa-0 ma-0">
          {{ certificate.name }} |
          <span class="issuer">
            {{ certificate.issuer }}
          </span>
          ( <span>{{ certificate.issueDate }}</span> )
          <v-btn
            text
            v-if="certificate.link"
            :href="certificate.link"
            target="_blank"
            class="mx-2"
          >
            <v-icon color="gray">mdi-link-variant</v-icon>
          </v-btn>
        </h4>
        <p v-if="certificate.description" class="description pa-0 ma-0 mt-1">
          {{ certificate.description }}
        </p>
      </template>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
import BaseDivider from "./BaseDivider.vue";
export default {
  computed: {
    ...mapState(useCreateResumeStore, ["resume", "selectedColor"]),
    linkStyle() {
      return "color: " + this.selectedColor;
    },
    certificateLink() {
      return this.$t("ph_certificate_link");
    },
  },
  components: { BaseDivider },
};
</script>

<style scoped>
.issuer {
  opacity: 0.8;
}
.description {
  opacity: 0.7;
  display: inline;
}
link {
  color: white;
}
</style>
