<template>
  <v-container
    v-if="resume.profilePicture"
    class="d-flex flex-column align-center"
  >
    <img class="img" :src="getImageUrl()" />
  </v-container>
  <v-container v-else class="my-0"></v-container>
</template>

<script>
import { mapState } from "pinia";
import { useCreateResumeStore } from "@/modules/create_resume/store/CreateResumeStore";
export default {
  computed: {
    ...mapState(useCreateResumeStore, ["resume"]),
  },
  methods: {
    getImageUrl() {
      return URL.createObjectURL(this.resume.profilePicture);
    },
  },
};
</script>

<style scoped>
.img {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  object-fit: contain;
  height: 200px;
  width: 200px;
}
</style>
